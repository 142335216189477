'use client';

import React from 'react';
import { wordForm } from '@frontend/jetlend-core/src/utils/langUtils';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import styles from './InvestorsLiquidityFeature.module.scss';
import { investorsJetLendStatisticsHandler } from '@app/ducks/investors/investors';
import SectionExpandedArea from '@app/components/server/ui/SectionExpandedArea/SectionExpandedArea';

interface IProps {
    domTable: React.ReactNode;
}

const parseLiquiditySpeedTime = (minutes: number): [number, string] => {
    if (minutes <= 60) {
        return [1, wordForm(1, 'hours')];
    }

    const hours = Math.trunc(minutes / 60);
    if (hours < 24) {
        return [ hours, wordForm(hours, 'hours') ];
    }

    const days = Math.ceil(hours / 24);

    return [ days, wordForm(days, 'day') ];
};

const InvestorsLiquidityFeature = (props: IProps) => {

    const { domTable } = props;
    const [stats] = useSimpleApiHandler(investorsJetLendStatisticsHandler);

    return (
        <div>
            <SectionExpandedArea className={styles['liquidity__dom-table']}>
                {domTable}
            </SectionExpandedArea>
            <div className={styles['liquidity__speed']}>
                {stats?.market_liquidity_speed?.map(item => {

                    const [time, timePostfix] = parseLiquiditySpeedTime(item.time);

                    return (
                        <div key={item.volume} className={styles['liquidity__speed__item']}>
                            <div className={styles['liquidity__speed-value']}>
                                {item.volume / 1000000}

                                <span className={styles['liquidity__speed-value-postfix']}>
                                    млн
                                </span>
                            </div>
                            <div className={styles['liquidity__speed-time']}>
                                {time}

                                <span className={styles['liquidity__speed-time-postfix']}>
                                    {timePostfix}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={styles['liquidity__note']}>
                * ликвидность не гарантирована и зависит от спроса и предложения на рынке
            </div>
        </div>
    );
};

export default InvestorsLiquidityFeature;
