'use client';

import React, {
    JSX,
    MouseEvent,
    useCallback,
    useState,
} from 'react';
import styles from './SlideLink.module.scss';

interface IProps {
    url: string;
    children: JSX.Element;
}

// Специальная ссылка для слайдера, которая не нажимается при перетаскивании элементов,
// но нажимается при намеренном нажатии
const SlideLink: React.FC<IProps> = props => {
    const [x, setX] = useState(0);

    const linkClickHandler = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
        if (Math.abs(e.clientX - x) > 5) {
            e.preventDefault();
        }
    }, [x]);

    const linkMouseDownHandler = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
        setX(e.clientX);
    }, [setX]);

    return (
        <a
            href={props.url}
            rel="noopener noreferrer nofollow"
            target="_blank"
            className={styles['link']}
            onClick={linkClickHandler}
            onMouseDown={linkMouseDownHandler}
        >
            {props.children}
        </a>
    );
};

export default SlideLink;