import {
    HistoryFeatureType,
    IFeatureBlockApiModel,
} from '@app/models/common/common';

export type FeatureSectionType = 'history' | 'null'

export const getBlockType = (block: IFeatureBlockApiModel<HistoryFeatureType>): FeatureSectionType => {
    switch (block.id) {
    case HistoryFeatureType.YEAR_2019:
    case HistoryFeatureType.YEAR_2020:
    case HistoryFeatureType.YEAR_2021:
    case HistoryFeatureType.YEAR_2022:
    case HistoryFeatureType.YEAR_2023:
    case HistoryFeatureType.YEAR_2024:
        return 'history';
    default:
        return 'null';
    }
};
