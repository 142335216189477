import Image from 'next/image';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './PersonImage.module.scss';

interface PersonImageProps {
    className: string;
    src: string | false;
    alt: string;
    width: number;
    height: number;
}

const PersonImage = ({
    className,
    src,
    alt,
    width,
    height,
}: PersonImageProps) => {
    const imageClassName = mergeClassNames([className, styles['photo']]);

    return src
        ? (
            <Image
                className={imageClassName}
                src={src}
                alt={alt}
                width={width}
                height={height}
            />
        )
        : (
            <div className={imageClassName} />
        );
};

export default PersonImage;