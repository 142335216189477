'use client';

import React, { Suspense } from 'react';
import {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import dynamic from 'next/dynamic';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    IInvestorsChartSectionApiModel,
    InvestorsChartType,
} from '@app/models/investors/investors';
import styles from './InvestorsChartSection.module.scss';
import OffscreenLoader from '../../ui/OffscreenLoader/OffscreenLoader';
import { ISectionBasedProps } from '@app/models/common/common';

const LazyInvestorsNplChart = dynamic(() => import('@app/components/client/investors/InvestorsNplChart/InvestorsNplChart'), { ssr: false });

type Props = ISectionBasedProps<IInvestorsChartSectionApiModel>;

const CHARTS_MAP: Record<InvestorsChartType, (screenWidth: number) => React.ReactNode> = ({
    [InvestorsChartType.NPL_15]: (screenWidth: number) => (
        <LazyInvestorsNplChart
            height={isMobile(screenWidth) ? 250 : 430}
            hideLegend
            area
            noBorder
        />
    ),
});

const InvestorsChartSection: React.FC<Props> = props => {
    const { width: screenWidth } = useWindowSize();

    return (
        <div>
            {props.section.header_description && (
                <div className={styles['name']}>
                    {props.section.header_description}
                </div>
            )}
            <div className={styles['shadow-container']}>
                <OffscreenLoader>
                    {({
                        inView,
                        ref,
                    }) => (
                        <div ref={ref} className={buildClassNames(styles, ['chart', `chart--${props.section.id}`])}>
                            <Suspense
                                fallback={(
                                    <LoaderBlock />
                                )}
                            >
                                {inView && CHARTS_MAP[props.section.id](screenWidth)}
                            </Suspense>
                        </div>
                    )}
                </OffscreenLoader>
            </div>
            {props.section.description && (
                <div className={styles['description']}>
                    {props.section.description}
                </div>
            )}
        </div>
    );
};

export default InvestorsChartSection;