'use client';

import React, { useCallback } from 'react';
import Button, { ButtonSize } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';
import { sendEvent } from '@app/ducks/common/analytics';

interface IProps {
    block?: boolean;
    size?: ButtonSize;
    width?: number;
    buttonText?: string;
    containerClassName?: string;
}

type Props = IProps & {
    startRegistration: typeof startRegistration;
    sendEvent: typeof sendEvent;
}

const BecomeBorrowerForm = (props: Props) => {
    const {
        block = false,
        size = 'big',
        startRegistration,
        sendEvent,
        width,
        buttonText = 'Подать заявку на займ',
        containerClassName,
    } = props;

    const didButtonClicked = useCallback(() => {
        sendEvent('borrower-button');
        startRegistration(ClientType.Borrower);
    }, []);

    return (
        <Button
            text={buttonText}
            size={size}
            noSpacing
            block={block}
            onClick={didButtonClicked}
            width={width}
            containerClassName={containerClassName}
        />
    );
};

export default connectFunctionalComponent(BecomeBorrowerForm, {
    dispatch: {
        startRegistration,
        sendEvent,
    },
}) as React.FC<IProps>;