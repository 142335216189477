'use client';

import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { investorsCompaniesHandler } from '@app/ducks/investors/investors';
import CompaniesSlider from './CompaniesSlider/CompaniesSlider';
import CompanyCard from './CompanyCard/CompanyCard';

/**
 * Компонент с слайдером списка компаний получателей займов
 */
export default function InvestorsCompanies() {
    const [ companies ] = useSimpleApiHandler(investorsCompaniesHandler);
    return (
        <CompaniesSlider>
            {companies?.map(company => (
                <CompanyCard
                    key={company.id}
                    company={company}
                />
            ))}
        </CompaniesSlider>
    );
}