'use client';

import React, { useEffect } from 'react';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import {
    formatAmountShortText,
    formatPercentNumber,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    calculatorHandler,
    summaryHandler,
} from '@app/ducks/borrowers/borrowers';
import {
    ICalculatorState,
    ICreditCalculatorResult,
} from '@app/models/borrowers/borrowers';
import styles from './BorrowersCalculatorSummary.module.scss';
import { creditCalculator } from '../BorrowersCalculator.helpers';
import { autoinvestRatingInfoHandler } from '@app/ducks/autoinvest';
import { IRatingGroupInfo } from '@app/models/autoinvest';

type Props = {
    state: ICalculatorState;
    ratingGroupInfo: IRatingGroupInfo[];
    summary: ICreditCalculatorResult;
    updateSummary: typeof summaryHandler.update;
}

const BorrowersCalculatorSummary = (props: Props) => {
    const {
        state,
        ratingGroupInfo,
        summary,
        updateSummary,
    } = props;

    useEffect(() => {
        if (ratingGroupInfo) {
            const result = creditCalculator(state, ratingGroupInfo);

            updateSummary(result);
        }
    }, [state, ratingGroupInfo]);

    return (
        <div className={styles['container']}>
            <div className={styles['statement']}>
                <div className={styles['text']}>Ежемесячный платеж:</div>
                <div className={buildClassNames(styles, ['value', 'main'])}>
                    {formatAmountShortText(summary.monthly_payment, ' ')}
                    <span className={styles['light']}>₽</span>
                </div>
            </div>
            <div className={styles['separator']} />
            <div className={styles['statement']}>
                <div className={styles['text']}>Процентная ставка:</div>
                <div className={buildClassNames(styles, ['value'])}>
                    {formatPercentNumber(summary.min_interest_rate_amount)} <span className={styles['light']}>% — </span >
                    {formatPercentNumber(summary.max_interest_rate_amount)} <span className={styles['light']}>%</span>
                </div>
            </div>
            <div className={styles['separator']} />
            <div className={styles['statement']}>
                <div className={styles['text']}>Вероятность одобрения:</div>
                <div className={buildClassNames(styles, ['value'])}>
                    {summary.probability_of_approval} <span className={styles['light']}>%</span>
                </div>
            </div>
        </div>
    );
};

export default connectFunctionalComponent(BorrowersCalculatorSummary, {
    selectors: {
        state: calculatorHandler.selector,
        summary: summaryHandler.selector,
        ratingGroupInfo: autoinvestRatingInfoHandler.selector,
    },
    dispatch: {
        updateSummary: summaryHandler.update,
    },
    fetch: {
        autoinvestRatingInfoHandler,
    },
});