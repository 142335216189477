'use client';

import React, {
    useCallback,
    useState,
} from 'react';
import Expand from 'react-expand-animated';
import IconButton from '@frontend/jetlend-web-ui/src/ui/inputs/IconButton/IconButton';
import { IFaqItemApiModel } from '@app/models/common/faq';
import styles from './FaqItem.module.scss';
import plusIcon from '@app/icons/plus-icon.svg';

export interface IProps {
    item: IFaqItemApiModel;
}

function renderMultilineContent(content: string): JSX.Element|React.ReactNode {
    if (!content) {
        return null;
    }

    const lines = content.split(/[\r\n]+/gm);

    return (
        <>
            {lines.map((line, index) =>
                <p key={index}>{line}</p>
            )}
        </>
    );
}

const FaqItem: React.FC<IProps> = ({ item }) => {
    const [ expanded, setExpanded ] = useState(false);

    const didToggleClicked = useCallback(() => {
        setExpanded(expand => !expand);
    }, []);

    return (
        <div className={styles['block']}>
            <div className={styles['header']}>
                <div className={styles['title']} onClick={didToggleClicked}>
                    {item.title}
                </div>
                <IconButton size="normal" icon={plusIcon} onClick={didToggleClicked} />
            </div>
            <Expand duration={400} open={expanded}>
                <div className={styles['body']}>
                    <div className={styles['description']}>
                        {renderMultilineContent(item.description)}
                    </div>
                </div>
            </Expand>
        </div>
    );
};

export default FaqItem;