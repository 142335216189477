import {
    FC,
    useCallback,
} from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import styles from './PartnerCalculatorStepsCard.module.scss';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import Image from '@app/components/server/Image/Image';
import ArrowIcon from '@app/icons/arrow-right-long.svg';
import { sendEvent } from '@app/ducks/common/analytics';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';

interface IProps {
    className?: string;
    startRegistration: typeof startRegistration;
}

const PartnerCalculatorStepsCard: FC<IProps> = props => {
    const {
        className,
        startRegistration,
    } = props;

    const didButtonClicked = useCallback(() => {
        sendEvent('partner-button');
        startRegistration(ClientType.Partner);
    }, []);

    return (
        <SectionPart className={className}>
            <ol className={styles['steps']}>
                <li className={styles['steps-item']}>
                    <button
                        type="button"
                        className={buildClassNames(styles, ['step', 'step--button'])}
                        onClick={didButtonClicked}
                    >
                        <p className={styles['text']}>Пройдите регистрацию</p>
                        <Image src={ArrowIcon} alt="" width={36} height={36} />
                    </button>
                </li>
                <li className={styles['steps-item']}>
                    <div className={styles['step']}>
                        <p className={styles['text']}>Менеджер свяжется с вами</p>
                    </div>
                </li>
                <li className={styles['steps-item']}>
                    <div className={styles['step']}>
                        <p className={styles['text']}>Получите доступ в личный кабинет и начните работу</p>
                    </div>
                </li>
            </ol>
        </SectionPart>
    );
};

export default connectFunctionalComponent(PartnerCalculatorStepsCard, {
    dispatch: {
        startRegistration,
    },
}) as React.FC<IProps>;
