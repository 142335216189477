import { ISectionBasedProps } from '@app/models/common/common';
import { MainPageFeaturesSectionApiModel } from '@app/models/mainPage/mainPage.';
import styles from './LinksFeature.module.scss';
import ShowMore from '@app/components/server/common/ShowMore/ShowMore';

type Props = ISectionBasedProps<MainPageFeaturesSectionApiModel>;

const LinksFeature : React.FC<Props> = props => {
    const { section } = props;
    return (
        <div className={styles['links-feature']}>
            <span className={styles['feature-description']}>
                {section.description}
            </span>
            <h2 className={styles['feature-title']}>{section.title}</h2>
            <div className={styles['links']}>
                <ShowMore className={styles['link']} linkText="Инвесторам" linkHref="/investor" />
                <ShowMore className={styles['link']} linkText="Предпринимателям" linkHref="/borrower" />
                <ShowMore className={styles['link']} linkText="Партнерам" linkHref="/partneram" />
            </div>
        </div>
    );
};

export default LinksFeature;

