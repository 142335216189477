import {
    FC,
    useMemo,
} from 'react';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './OnlineFeatureCard.module.scss';
import { IPartnersFeatureBlockApiModel } from '@app/models/partners/partners';
import Image from '@app/components/server/Image/Image';
import DecorativeRectangles from '../DecorativeRectangles/DecorativeRectangles';

export enum OnlineFeatureCardType {
    DEFAULT = 'default',
    LONG = 'long',
}

interface IProps {
    className?: string;
    sectionBlock: IPartnersFeatureBlockApiModel;
    type?: OnlineFeatureCardType;
    imageSrc?: string;
}

const OnlineFeatureCard: FC<IProps> = props => {
    const {
        className,
        sectionBlock,
        type = OnlineFeatureCardType.DEFAULT,
        imageSrc,
    } = props;

    const classNames = useMemo(
        () =>
            mergeClassNames([
                className,
                styles['card'],
                type === OnlineFeatureCardType.LONG ? styles['card--long'] : '',
            ]),
        [type, className]
    );
    return (
        <div className={classNames}>
            <div className={styles['title-container']}>
                <h3 className={styles['title']}>{sectionBlock.title}</h3>
            </div>
            <div className={styles['description-container']}>
                <p className={styles['description']}>{sectionBlock.description}</p>
            </div>
            <div className={styles['image-container']}>
                {type === OnlineFeatureCardType.DEFAULT
                    ? (
                        <div className={styles['image']}>
                            <Image src={imageSrc} alt="feature-icon" width={86} height={86} />
                        </div>
                    )
                    : (
                        <DecorativeRectangles titleText={sectionBlock.value} descriptionText={sectionBlock.title} />
                    )}
            </div>
        </div>
    );
};

export default OnlineFeatureCard;
