'use client';

import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    useCallback,
    useRef,
    useState,
} from 'react';
import styles from './ExpandControl.module.scss';

interface IProps {
    checkboxClassName?: string;
    labelClassName?: string;
    id: string;
}

const ExpandControl : React.FC<IProps> = props => {
    const {
        checkboxClassName,
        labelClassName,
        id,
    } = props;

    const controlRef = useRef<HTMLInputElement>(null);

    const [checked, setChecked] = useState(false);

    const didChanged = useCallback(() => {
        if (checked) {
            controlRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        setChecked(prev => !prev);
    }, [checked]);

    return (
        <>
            <label
                className={mergeClassNames([styles['label'], labelClassName])}
                htmlFor={id}
            >
                {checked ? 'Свернуть' : 'Развернуть'}
            </label>
            <input
                className={mergeClassNames([styles['checkbox'], checkboxClassName])}
                type="checkbox"
                onChange={didChanged}
                ref={controlRef}
                id={id}
            />
        </>
    );
};

export default ExpandControl;

