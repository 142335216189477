import { FC } from 'react';
import styles from './WithdrawCard.module.scss';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import WithdrawExample from './WithdrawExample/WithdrawExample';

interface IProps {
    className?: string;
    titleText: string;
    descriptionText: string;
}

const WithdrawCard: FC<IProps> = props => {
    const {
        className,
        titleText,
        descriptionText,
    } = props;
    return (
        <SectionPart className={className}>
            <div className={styles['content']}>
                <div className={styles['title-wrapper']}>
                    <h3 className={styles['title']}>{titleText}</h3>
                </div>
                <div className={styles['description-wrapper']}>
                    <p className={styles['description']}>{descriptionText}</p>
                </div>
                <div className={styles['example-wrapper']}>
                    <h4 className={styles['example-title']}>Уведомления</h4>
                    <WithdrawExample className={styles['example-transaction']} amount={1536000} />
                    <p className={styles['example-text']}>Ранее</p>
                    <WithdrawExample className={styles['example-transaction']} amount={746000} />
                    <WithdrawExample className={styles['example-transaction']} amount={835000} />
                </div>
            </div>
        </SectionPart>
    );
};

export default WithdrawCard;
