'use client';

import React, { useCallback } from 'react';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import BecomeInvestorForm from '@app/components/client/investors/BecomeInvestorForm/BecomeInvestorForm';

import styles from './investmentCalculatorFooterButtons.module.scss';

export interface IProps {
    /**
     * Функция, при вызове которой открывается модальное окна с таблицей со стратегиями
     */
    onModalOpen?: VoidFunction;
}

/**
* Компонент для отрисовки кнопок в футере калькулятора
*/
const InvestmentCalculatorFooterButtons = (props: IProps) => {
    const { onModalOpen } = props;
    const didModalOpened = useCallback(() => onModalOpen && onModalOpen(), [onModalOpen]);

    return (
        <div className={styles['calculator-buttons']}>
            <div className={styles['calculator-button']}>
                {onModalOpen && (
                    <Button
                        text="Подробнее"
                        onClick={didModalOpened}
                        outline
                        block
                        size="big"
                    />
                )}
            </div>
            <div className={styles['calculator-button']}>
                <BecomeInvestorForm type="button-block" />
            </div>
        </div>
    );
};

export default InvestmentCalculatorFooterButtons;
