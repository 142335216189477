import { formatPercentExt } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import React from 'react';
import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { AutoInvestStrategyType } from '@app/models/autoinvest';
import {
    autoInvestStrategiesInfoHandler,
    currentStrategyHandler,
    RATINGS_LEVEL,
} from '@app/ducks/autoinvest';

import styles from './strategiesTable.module.scss';

/**
 * Компонент для отрисовки таблицы стратегий в блоке калькулятора АИ
 */
const StrategiesTable = () => {
    const [{ selectedStrategyType: currentStrategy }] = useObjectHandler(currentStrategyHandler);
    const [strategies] = useSimpleApiHandler(autoInvestStrategiesInfoHandler);

    const ceilTitleClassNames = (strategy: AutoInvestStrategyType) => buildClassNames(styles, [
        'ceil-title',
        strategy === currentStrategy && 'ceil-title--active',
    ]);

    const ceilClassNames = (strategy?: AutoInvestStrategyType, last = false) => buildClassNames(styles, [
        'ceil',
        last && 'ceil--last',
        strategy === currentStrategy && 'ceil--active',
    ]);

    return (
        <div className={styles['table-container']}>
            <table className={styles['table']}>
                <thead>
                    <td className={styles['ceil-title']}>Показатель</td>
                    {strategies?.map(dataItem => (
                        <td key={`title--${dataItem.title}`} className={ceilTitleClassNames(dataItem.strategy)}>{dataItem.title}</td>
                    ))}
                </thead>
                <tr>
                    <td className={styles['ceil']}>Вероятная процентная доходность</td>
                    {strategies?.map(dataItem => (
                        <td
                            key={`yield--${dataItem.title}`}
                            className={ceilClassNames(dataItem.strategy)}
                        >
                            {`${formatPercentExt(dataItem.expected_min_interest_rate, { fractionDigits: 2 })} 
                            - ${formatPercentExt(dataItem.expected_max_interest_rate, { fractionDigits: 2 })}`}
                        </td>
                    ))}
                </tr>
                <tr>
                    <td className={styles['ceil']}>Доверительный интервал доходности</td>
                    {strategies?.map(dataItem => (
                        <td
                            key={`interval--${dataItem.title}`}
                            className={ceilClassNames(dataItem.strategy)}
                        >
                            {formatPercentExt(0.95)}
                        </td>
                    ))}
                </tr>
                <tr>
                    <td className={ceilClassNames(undefined, true)}>Лимиты в рейтинги заемщиков</td>
                    {strategies?.map(dataItem => (
                        <td
                            key={`limit--${dataItem.title}`}
                            className={ceilClassNames(dataItem.strategy, true)}
                        >
                            {RATINGS_LEVEL[dataItem.strategy]}
                        </td>
                    ))}
                </tr>
            </table>
        </div>
    );
};

export default StrategiesTable;
