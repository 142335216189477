'use client';

import React, { useCallback } from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { ChartItem } from './ChartSection';

import styles from './chartSection.module.scss';

export interface IProps {
    tabId: ChartItem;
    title: string;
    active: boolean;

    onChange: (tabId: ChartItem) => void;
}

const ChartSection: React.FC<IProps> = props => {
    const {
        tabId,
        title,
        active,
        onChange,
    } = props;

    const didTabChanged = useCallback(() => onChange(tabId), [tabId]);

    const tabClassNames = buildClassNames(styles, [
        'tab',
        active && 'tab--active',
    ]);

    return <button className={tabClassNames} type="button" key={tabId} onClick={didTabChanged}>{title}</button>;
};

export default ChartSection;