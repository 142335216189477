'use client';

import React from 'react';
import { Settings } from 'react-slick';
import {
    isDesktop,
    isMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import dynamic from 'next/dynamic';
import CommonCard from '@app/components/server/investors/InvestorsCommonSlideSection/CommonCard/CommonCard';
import styles from './InvestorsCommonSlideSection.module.scss';
import './slider.scss';
import OffscreenLoader from '../../ui/OffscreenLoader/OffscreenLoader';

const LazySlide = dynamic(() => import('react-slick').then(module => module), { ssr: false });

export interface IProps {
    children: React.ReactNode[];
    card?: boolean;
    amount?: number;
    noBorder?: boolean;
    showNextSlide?: boolean;
}

const RESPONSIVE_SETTINGS = [
    {
        breakpoint: 4000,
        settings: {
            slidesToShow: 1.25,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 3000,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 1600,
        settings: {
            slidesToShow: 0.75,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 1100,
        settings: {
            slidesToShow: 0.5,
            slidesToScroll: 0.5,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 0.25,
            slidesToScroll: 0.25,
        },
    },
];

const getSettings = (amount: number) => {
    const toInteger = (value: number) => Math.trunc(amount * value) || 1;

    return RESPONSIVE_SETTINGS.map(item => ({
        ...item,
        settings: {
            slidesToShow: toInteger(item.settings.slidesToShow),
            slidesToScroll: toInteger(item.settings.slidesToScroll),
        },
    }));
};

const InvestorsCommonSlideSection: React.FC<IProps> = props => {
    const {
        card = true,
        amount = 4,
    } = props;

    const { width: screenWidth } = useWindowSize();

    const NextArrow = (
        <div className={mergeClassNames([styles['arrows'], styles['arrow-right']])}>
            <svg viewBox="0 0 100 100">
                <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#333" transform="translate(100, 100) rotate(180) " />
            </svg>
        </div>
    );

    const PrevArrow = (
        <div className={mergeClassNames([styles['arrows'], styles['arrow-left']])}>
            <svg viewBox="0 0 100 100">
                <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#333" />
            </svg>
        </div>
    );

    const SLIDER_SETTINGS: Settings = {
        children: [],
        arrows: isDesktop(screenWidth),
        dots: !props.showNextSlide && (isMobile(screenWidth) || isTablet(screenWidth)),
        autoplay: false,
        infinite: true,
        className: styles['slider'],
        speed: 1200,
        responsive: getSettings(amount),
        nextArrow: NextArrow,
        prevArrow: PrevArrow,
    };

    return (
        <OffscreenLoader>
            {({
                ref,
                inView,
            }) => (
                <div ref={ref} className={styles['container']}>
                    <div className={props.showNextSlide ? styles['slim'] : undefined}>
                        {inView && (
                            <LazySlide {...SLIDER_SETTINGS}>
                                {props.children.map((item, i) => (
                                    <React.Fragment key={i}>
                                        {card
                                            ? <CommonCard className={styles['item-card']} noBorder={props.noBorder}>{item}</CommonCard>
                                            : <div className={styles['item-wrapper']}>{item}</div>
                                        }
                                    </React.Fragment>
                                ))}
                            </LazySlide>
                        )}
                    </div>
                </div>
            )}
        </OffscreenLoader>
    );
};

export default InvestorsCommonSlideSection;