import { isFloatValue } from '@core/validators/requireFloat';
import { parseFloatExt } from '@core/validators/common';
import {
    CURRENCY_RUB,
    splitNumberWithDigestGroups,
} from '@core/formatters/formatUtils';
import { IFieldValueConverter } from './common';

/**
 * Конвертер значений для полей ввода сумм.
 * Преобразует введенное значение в число.
 * Запрещает ввод символов не относящихся к цифрам, не дает ввести более 2х знаков после запятой.
 *
 * @Category Fields
 */
export const FieldAmountValueConverter: IFieldValueConverter = {
    postfix: CURRENCY_RUB,
    format: value => {
        if (value && value.toString) {
            const text: string = value.toString();
            const number = parseFloatExt(text);

            // При наличии разделителя, необходимо отрисовать столько же знаков после запятой, сколько ввел пользователь
            const dotIndex = text.lastIndexOf('.');
            const commaIndex = text.lastIndexOf(',');
            const lastIndex = Math.max(dotIndex, commaIndex);
            if (lastIndex > 0) {
                const distance = text.length - lastIndex - 1;

                // Если пользователь ввел запятую, но без цифр, мы должны оставить запятую
                if (distance === 0) {
                    return `${splitNumberWithDigestGroups(number, 0)},`;
                }

                return splitNumberWithDigestGroups(number, Math.min(distance, 2));
            }
            return splitNumberWithDigestGroups(number, 0);

        }

        return value;
    },
    normalize: (value, previousValue) => {
        // Запрещает ввод более двух знаков после запятой. Для валюты это не имеет смысла.
        if (typeof value === 'string' && isFloatValue(value)) {
            const dotIndex = value.lastIndexOf('.');
            const commaIndex = value.lastIndexOf(',');
            const lastIndex = Math.max(dotIndex, commaIndex);
            if (lastIndex > 0) {
                const distance = value.length - lastIndex - 1;
                if (distance > 2) {
                    return value.substring(0, value.length - distance + 2);
                }
            }
        }

        return value;
    },
    parse: (text: string) => {
        if (typeof text !== 'string') {
            return text;
        }

        let inputText = text;

        // Удаляем все невалидные символы
        inputText = inputText.replace(/[^0-9.,-]+/g, '');

        const value = parseFloatExt(inputText);

        // Сохраняем значение в текстовом виде для граничных случаев
        if (Number.isNaN(value) || value === undefined || value === null || /[.,]\d*?$/i.test(inputText)) {
            return inputText;
        }

        return value;
    },
};