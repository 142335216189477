import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { useInView } from 'react-intersection-observer';
import styles from './animations.module.scss';

export type AnimationType = false | 'none' | 'slide-up' | 'slide-left' | 'slide-right';

export type UseAnimationHookResponse = [
    (node?: Element) => void,
    string,
    boolean
];

function isHasAnimation(animation: AnimationType): boolean {
    return animation !== false && animation !== 'none';
}

export function buildAnimationClassName(animation: AnimationType, isActive: boolean) {
    if (!isHasAnimation(animation)) {
        return undefined;
    }

    return buildClassNames(styles, [
        `animation--${animation}`,
        isActive && 'animation--active',
    ]);
}

export function useAnimation(animation: AnimationType, threshold = 1.0): UseAnimationHookResponse {
    const hasAnimation = isHasAnimation(animation);

    const {
        ref,
        inView,
    } = useInView({
        triggerOnce: true,
        threshold: (animation === 'slide-up' ? 0.1 : 0.4) * threshold,
        fallbackInView: true,
        skip: !hasAnimation,
    });

    const className = buildAnimationClassName(animation, inView);

    return [
        ref,
        className,
        inView,
    ];
}