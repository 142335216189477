'use client';

import React, {
    FC,
    useCallback,
} from 'react';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { IFeatureBlock } from '@app/models/aboutPlatform/aboutPlatform';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import styles from './PlatformFeature.module.scss';
import { startRegistration } from '@app/ducks/common/registration';
import { sendEvent } from '@app/ducks/common/analytics';
import { ClientType } from '@app/models/common/common';

type Props = IFeatureBlock & {
    startRegistration: typeof startRegistration;
    sendEvent: typeof sendEvent;
};


const PlatformFeature: FC<Props> = props => {
    const {
        title,
        description,
        value,
        id,
        startRegistration,
        sendEvent,
    } = props;

    const didBecomeInvestorClicked = useCallback(() => {
        startRegistration(ClientType.Investor);

        sendEvent('investor-button');
    }, []);

    const didBecomeBorrowerClicked = useCallback(() => {
        startRegistration(ClientType.Borrower);

        sendEvent('borrower-button');
    }, []);

    const didButtonClicked = useCallback(() => {
        switch (id) {
        case 'investors':
            didBecomeInvestorClicked();
            return;
        case 'borrowers':
            didBecomeBorrowerClicked();
        }
    }, [id, didBecomeBorrowerClicked, didBecomeInvestorClicked]);

    return (
        <SectionPart className={styles['container']}>
            <div className={styles['content']}>
                <div className={styles['title']}>{title}</div>
                <div className={styles['description']}>{description}</div>

                <button className={mergeClassNames([styles['value'], 'link'])} onClick={didButtonClicked}>{value}</button>
            </div>
        </SectionPart>
    );
};

export default connectFunctionalComponent(PlatformFeature, {
    dispatch: {
        startRegistration,
        sendEvent,
    },
}) as React.FC<IFeatureBlock>;
