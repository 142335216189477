import React from 'react';
import { Markup } from 'interweave';
import { parseDate } from '@frontend/jetlend-core/src/formatters/formatUtils';
import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';
import {
    AutoInvestStrategyType,
    IStrategyGroupInfo,
} from '@app/models/autoinvest';
import BlockSelectionButton from '../BlockSelectionButton/BlockSelectionButton';

export interface IProps {
    className?: string;
    textClassName?: string;
    strategy: IStrategyGroupInfo;
    currentStrategy: AutoInvestStrategyType;
    onClick: (strategy: AutoInvestStrategyType) => void;
    icon?: string;
}

const ChangeStrategyButton:React.FC<IProps> = props => {
    const {
        strategy,
        currentStrategy,
        icon,
        onClick,
    } = props;
    const date = parseDate(strategy?.risk_test_block_date).format('DD.MM.Y');

    if (!strategy) {
        return null;
    }

    return (
        <Tooltip title={<Markup noWrap content={strategy.description} />}>
            <BlockSelectionButton
                title={strategy.title}
                selected={currentStrategy === strategy.strategy}
                value={strategy.strategy}
                onChange={onClick}
                icon={icon}
                date={date}
            />
        </Tooltip>
    );
};

export default ChangeStrategyButton;