import {
    Children,
    Fragment,
    ReactNode,
    useMemo,
    useRef,
} from 'react';
import { SlideProps } from 'react-slideshow-image';
import dynamic from 'next/dynamic';
import {
    isMobile,
    isTabletPortrait,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './HistorySlider.module.scss';
import OffscreenLoader from '@app/components/server/ui/OffscreenLoader/OffscreenLoader';
import ArrowRight from '@app/icons/slider-chevron-right.svg';
import Image from '@app/components/server/Image/Image';

import './slider.scss';

const LazySlide = dynamic(() => import('react-slideshow-image').then(module => module.Slide), { ssr: false });

export interface IProps {
    children: ReactNode;
    noBorder?: boolean;
    showNextSlide?: boolean;
}

const RESPONSIVE_SETTINGS = [
    {
        breakpoint: 1600,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 769,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 0,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
];

export default function HistorySlider({
    showNextSlide,
    children,
}: IProps) {
    const sliderRef = useRef(null);

    const { width: windowWidth } = useWindowSize();
    const isMobileView = isMobile(windowWidth);
    const isTabletPortraitView = isTabletPortrait(windowWidth);

    const SLIDER_SETTINGS: SlideProps = useMemo(() => ({
        children,
        arrows: !isMobileView && !isTabletPortraitView,
        indicators: false,
        autoplay: false,
        infinite: false,
        cssClass: styles['slider'],
        duration: 1200,
        transitionDuration: 400,
        canSwipe: true,
        ref: sliderRef,
        responsive: RESPONSIVE_SETTINGS,
        slidesToScroll: 1,
        slidesToShow: 2,
        nextArrow: (
            <button
                type="button"
                className={buildClassNames(styles, ['arrow-wrapper', 'arrow-wrapper--right'])}
            >
                <Image src={ArrowRight} alt="" width={15} height={15} />
            </button>
        ),
        prevArrow: (
            <button
                type="button"
                className={buildClassNames(styles, ['arrow-wrapper', 'arrow-wrapper--left'])}
            >
                <Image src={ArrowRight} alt="" width={15} height={15} />
            </button>
        ),
    }), [isMobileView, isTabletPortraitView, children]);

    return (
        <OffscreenLoader>
            {({
                ref,
                inView,
            }) => (
                <div ref={ref} className={styles['container']}>
                    <div className={showNextSlide && styles['slim']}>
                        {inView && (
                            <LazySlide {...SLIDER_SETTINGS} infinite={false}>
                                {Children.map(children, (child, i) => (
                                    <Fragment key={i}>
                                        <div className={styles['item-wrapper']}>{child}</div>
                                    </Fragment>
                                ))}
                            </LazySlide>
                        )}
                    </div>
                </div>
            )}
        </OffscreenLoader>
    );
}
