'use client';

import React from 'react';
import {
    isTabletPortrait,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import InvestorsCommonSlideSection from '@app/components/server/investors/InvestorsCommonSlideSection/InvestorsCommonSlideSection';
import PartnersItem from '@app/components/server/common/PartnersSection/PartnersItem';
import SlideLink from '@app/components/server/investors/InvestorsCommonSlideSection/SlideLink/SlideLink';
import {
    ISectionBasedProps,
    PartnersSectionApiModel,
} from '@app/models/common/common';

type Props = ISectionBasedProps<PartnersSectionApiModel>

const PartnersSection: React.FC<Props> = props => {
    const { width } = useWindowSize();
    const isTablet = isTabletPortrait(width);

    return (
        <InvestorsCommonSlideSection
            card={false}
            amount={isTablet ? 14 : 7}
        >
            {props.section.items?.map((item, index) => (
                <SlideLink key={item.url ?? `__${index}`} url={item.url}>
                    <PartnersItem {...item} />
                </SlideLink>
            ))}
        </InvestorsCommonSlideSection>
    );
};

export default PartnersSection;
