import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './ShowMore.module.scss';

import Image from '@app/components/server/Image/Image';
import UpRightArrow from '@app/icons/up-right-arrow.svg';

interface IProps {
    linkText?: string;
    linkHref?: string;
    className?: string;
}

const ShowMore : React.FC<IProps> = props => {
    const {
        linkText = 'Подробнее',
        linkHref = '/investor',
        className,
    } = props;

    const linkClassName = mergeClassNames([
        buildClassNames(styles, [
            'show-more-link',
        ]),
        className,
    ]);

    return (
        <a className={linkClassName} href={linkHref} rel="nofollow">
            <span className={styles['link-text']}>{linkText}</span>
            <Image src={UpRightArrow} alt="" width={11} height={11} />
        </a>
    );
};

export default ShowMore;

