import { CreditPortfolioApiModel } from '@app/models/investments/investments';

const MEDIUM_BREAKPOINT = 1350;
const SMALL_BREAKPOINT = 1050;
export const EXTRA_SMALL_BREAKPOINT = 860;
const XX_SMALL_BREAKPOINT = 480;

// Получаем значение расстояния между колонками
export const getColumnsOffset = (width: number) => {

    if (width <= SMALL_BREAKPOINT) {
        return width/19;
    }

    return width/20;
};

// Получаем ширину самих колонок
export const getColumnsWidth = (width: number) => {
    if (width <= MEDIUM_BREAKPOINT) {
        return width/30;
    }

    return width/26;
};

// Получаем размер шрифта для лейблов снизу колонок
export const getLabelFontSize = (width: number) => {
    if (width <= EXTRA_SMALL_BREAKPOINT) {
        return 14;
    }

    if (width <= SMALL_BREAKPOINT) {
        return 12;
    }

    return 14;
};

// Получаем отступ лейблов от колонок снизу
export const getLabelDYValue = (width: number) => {
    if (width <= EXTRA_SMALL_BREAKPOINT) {
        return -22;
    }

    return -40;
};

// Получаем текст лейблов, чтобы сделать перенос на мобильных устройствах
export const getLabelTitle = (width: number, title: string, quartersCount: number, x: number) => {
    if (width <= EXTRA_SMALL_BREAKPOINT && quartersCount === 1 && x > 3) {
        return `20${title.split(' ')[1].replace('г', ' ')} г`;
    }

    if (width <= EXTRA_SMALL_BREAKPOINT) {
        return '';
    }
    return title;
};

// Получаем отступ графика от правого края по оси X
export const getDomainPadding = (width: number) => {
    if (width <= SMALL_BREAKPOINT) {
        return 5;
    }

    return 40;
};

// Получаем отступы графика со всех сторон относительно родительского компонента
export const getChartPadding = (width: number) => {
    if (width <= XX_SMALL_BREAKPOINT) {
        return {
            left: 45,
            top: 30,
            right: 0,
            bottom: 40,
        };
    }

    if (width <= EXTRA_SMALL_BREAKPOINT) {
        return {
            left: 60,
            top: 40,
            right: 20,
            bottom: 40,
        };
    }

    return {
        left: 100,
        top: 60,
        right: 40,
        bottom: 60,
    };
};

// Получаем значение скругления колонок
export const getBarCornerRadius = (width: number) => {
    if (width <= XX_SMALL_BREAKPOINT) {
        return 4;
    }

    if (width <= EXTRA_SMALL_BREAKPOINT) {
        return 8;
    }

    return 12;
};

// Цвета графика
const colors = {
    '1К': ['#009EE233', '#009EE27A', '#3BB3E7', '#009EE2'],
    '2К': ['#009EE247', '#009EE285', '#28ADE6', '#0099DA'],
    '3К': ['#009EE252', '#009EE299', '#15A6E4', '#008DC9'],
    '4К': ['#009EE266', '#4FBAEA', '#009EE2', '#008DD0'],
};

// Получаем отформатированные данные
export const getFormattedData = (data: CreditPortfolioApiModel) => {
    const result = [];

    // Определение квартала по месяцу
    function getQuarter(month) {
        if (month >= 1 && month <= 3) {
            return '1К';
        }
        if (month >= 4 && month <= 6) {
            return '2К';
        }
        if (month >= 7 && month <= 9) {
            return '3К';
        }
        if (month >= 10 && month <= 12) {
            return '4К';
        }
    }

    // Группируем данные по кварталам
    data.forEach(item => {
        const date = new Date(item.start_date);
        const year = String(date.getFullYear()).slice(2); // Получаем последние две цифры года ('21', '22', и т.д.)
        const month = date.getMonth() + 1; // Получаем месяц
        const quarter = getQuarter(month); // Определяем квартал

        // Генерируем title на основе квартала и года
        const title = `${quarter} ${year}г`;

        const xValue = parseInt(year) - 20; // Преобразуем год в x (начинаем с 1 для 2021 года)

        // Найти или создать массив для данного квартала
        let quarterArray = result.find(arr => arr.length && arr[0].title.startsWith(quarter));
        if (!quarterArray) {
            quarterArray = [];
            result.push(quarterArray);
        }

        // Добавляем объект с title, цветом и другими полями
        quarterArray.push({
            title,
            x: xValue,
            y: item.amount,
            color: colors[quarter][xValue - 1], // Используем цвет из переданного объекта
            date: item.start_date,
        });
    });

    return result;
};

export const getLastXAxisValue = (quartersCount: number) => {
    switch (quartersCount) {
    case 1:
        return [3.85, 4];
    case 2:
        return [4, 4.05];
    case 3:
        return [4, 4.25];
    default:
        return [4, 4.5];
    }
};

export const getLabelDXValue = (quartersCount: number, width: number) => {
    switch (quartersCount) {
    case 1:
        return 200;
    case 2:
        return width <= XX_SMALL_BREAKPOINT ? -15 : -25;
    case 3:
        return -10;
    default:
        return 0;
    }
};
