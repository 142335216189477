'use client';

import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import { useDispatch } from 'react-redux';
import styles from './DocumentsFilter.module.scss';
import {
    applyCategoryFilter,
    documentsPageStateHandler,
} from '@app/ducks/documents/documents';
import { DocumentCategory } from '@app/models/documents/documents';
import Dropdown from '@app/components/server/common/Dropdown/Dropdown';
import { DOCUMENT_CONFIGURATION } from '../DocumentsBlock/documents.const';
import FilterButtonsBlock from '@app/components/server/common/FilterButtonsBlock/FilterButtonsBlock';
import useQueryParams from '@app/hooks/common/useQueryParams';

interface IProps {
    className?: string;
}

const DocumentsFilter : React.FC<IProps> = props => {
    const { className } = props;
    const dispatch = useDispatch();
    const [, setParam ] = useQueryParams('type');

    const [ state ] = useObjectHandler(documentsPageStateHandler);
    const { selectedCategory } = state;

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const didDropDownClick = useCallback(() => {
        setIsDropdownOpen(prev => !prev);
    }, [setIsDropdownOpen]);

    const currentDepartment = useMemo(() => DOCUMENT_CONFIGURATION[selectedCategory], [selectedCategory]);

    const didDocumentCategorySelect = useCallback((category: DocumentCategory) => () => {
        setIsDropdownOpen(false);
        dispatch(applyCategoryFilter(category));
        setParam(category, false);
    }, [dispatch]);

    useEffect(() => {
        setParam(selectedCategory, false);
    }, []);

    return (
        <div className={mergeClassNames([styles['documents-filter'], className])}>
            <div className={styles['dropdown-wrapper']}>
                <Dropdown
                    className={styles['dropdown']}
                    title={currentDepartment.text}
                    isOpen={isDropdownOpen}
                    didDropDownClick={didDropDownClick}
                >
                    <FilterButtonsBlock
                        elements={DOCUMENT_CONFIGURATION}
                        filter={selectedCategory}
                        onElementSelect={didDocumentCategorySelect}
                    />
                </Dropdown>
            </div>
            <div className={styles['filter']}>
                <FilterButtonsBlock
                    elements={DOCUMENT_CONFIGURATION}
                    filter={selectedCategory}
                    onElementSelect={didDocumentCategorySelect}
                />
            </div>
        </div>
    );
};

export default DocumentsFilter;
