
import { DocumentCategory } from '@app/models/documents/documents';
import allDocumentIcon from '@app/icons/document-all.svg';
import allDocumentActiveIcon from '@app/icons/document-all--active.svg';
import disclosureDocumentIcon from '@app/icons/document-disclosure.svg';
import disclosureDocumentActiveIcon from '@app/icons/document-disclosure--active.svg';
import archiveDocumentIcon from '@app/icons/document-archive.svg';
import archiveDocumentActiveIcon from '@app/icons/document-archive--active.svg';

export interface IDocumentConfig {
    text: string;
    icon: string;
    iconActive: string;
}

export const DOCUMENT_CONFIGURATION: Record<DocumentCategory, IDocumentConfig> = {
    [DocumentCategory.ALL]: {
        text: 'Все',
        icon: '',
        iconActive: '',
    },
    [DocumentCategory.DOCUMENT]: {
        text: 'Документы',
        icon: allDocumentIcon,
        iconActive: allDocumentActiveIcon,
    },
    [DocumentCategory.INFORMATION_DISCLOSURE]: {
        text: 'Раскрытие информации',
        icon: disclosureDocumentIcon,
        iconActive: disclosureDocumentActiveIcon,
    },
    [DocumentCategory.ARCHIVE]: {
        text: 'Архив',
        icon: archiveDocumentIcon,
        iconActive: archiveDocumentActiveIcon,
    },
};

