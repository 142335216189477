import React from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './CommonCard.module.scss';

export interface IProps extends React.PropsWithChildren{
    noBorder?: boolean;
}

const CommonCard: React.FC<IProps> = props => {
    const classes = buildClassNames(styles, [
        'card',
        props.noBorder && 'card--no-border',
    ]);
    return <div className={classes}>{props.children}</div>;
};

export default CommonCard;