import {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import Range, {
    IEditorConfig,
    NumberValuesRange,
} from '@frontend/jetlend-web-ui/src/ui/inputs/Range/Range';
import { formatAmountShortText } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { FieldAmountValueConverter } from '@frontend/jetlend-core/src/fields/amountFieldConverter';
import { NumberFieldConverter } from '@frontend/jetlend-core/src/fields/numberFieldConverter';
import { useRangeNumberEditorHandler } from '@frontend/jetlend-web-ui/src/ui/inputs/Range/utils';
import PropertyItem from '@frontend/jetlend-web-ui/src/components/PropertyItem/PropertyItem';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { withDebounce } from '@frontend/jetlend-core/src/async/debounce';
import Loader from '@frontend/jetlend-web-ui/src/ui/loaders/Loader';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import { IPartnerCalculatorResult } from '@app/models/partners/partners';
import { evaluateIncomes } from '../PartnerCalculator.helpers';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import styles from './PartnerCalculatorFormCard.module.scss';
import { sendEvent } from '@app/ducks/common/analytics';

interface IProps {
    className?: string;
    sendEvent: (eventName: string) => void;
}

const BORROWERS_COUNT_RANGE_CONFIG: NumberValuesRange = {
    min: 1,
    max: 100,
    step: 1,
    labelFormatter: v => formatAmountShortText(v),
};

const AVERAGE_BORROW_AMOUNT_RANGE_CONFIG: NumberValuesRange = {
    min: 500000,
    max: 10000000,
    step: 100000,
    labelFormatter: v => formatAmountShortText(v),
};

const BORROWERS_COUNT_RANGE_MIN_STEP = 1;
const AVERAGE_BORROW_AMOUNT_RANGE_MIN_STEP = 100000;
const INCOME_PERCENT = 0.02;

const PartnerCalculatorFormCard: FC<IProps> = props => {
    const {
        className,
        sendEvent,
    } = props;

    const [borrowersCount, setBorrowersCount] = useState<number>(10);
    const [averageBorrowAmount, setAverageBorrowAmount] = useState<number>(2000000);
    const [result, setResult] = useState<IPartnerCalculatorResult>(null);

    const BORROWERS_COUNT_EDITOR_CONFIG = useMemo<IEditorConfig>(() => ({
        ...NumberFieldConverter,
        postfix: undefined,
    }), []);

    const didChanged = useMemo(() => {
        const debouncedSendEvent = withDebounce(sendEvent);

        return () => {
            debouncedSendEvent('partner-calculator--changed');
        };
    }, []);

    const didBorrowersCountChanged = useCallback(newValue => {
        setBorrowersCount(newValue);
        didChanged();
    }, []);

    const didAverageBorrowAmountChanged = useCallback(newValue => {
        setAverageBorrowAmount(newValue);
        didChanged();
    }, []);

    const borrowersCountRangeEditorHandler = useMemo(
        () =>
            // useRangeNumberEditorHandler is not a React Hook
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useRangeNumberEditorHandler(BORROWERS_COUNT_RANGE_CONFIG, {
                minStep: BORROWERS_COUNT_RANGE_MIN_STEP,
            }),
        []
    );

    const averageBorrowAmountRangeEditorHandler = useMemo(
        () =>
            // useRangeNumberEditorHandler is not a React Hook
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useRangeNumberEditorHandler(AVERAGE_BORROW_AMOUNT_RANGE_CONFIG, {
                minStep: AVERAGE_BORROW_AMOUNT_RANGE_MIN_STEP,
            }),
        []
    );

    const factory = useCallback(values => setResult(evaluateIncomes(values)), []);

    useEffect(() => {
        factory({
            borrowersCount,
            averageBorrowAmount,
            expectedIncome: borrowersCount * averageBorrowAmount * INCOME_PERCENT,
        });
    }, [factory, borrowersCount, averageBorrowAmount]);

    const incomeValue = useMemo(
        () =>
            result
                ? (
                    <div className={styles['result-value-wrapper']}>
                        <span className={styles['result-value']}>{formatAmountShortText(result?.expectedIncome, '')} </span>
                        <span className={styles['result-currency']}>₽</span>
                    </div>
                )
                : (
                    <Loader size="normal" />
                ),
        [result]
    );

    const borrowersLabel = useCallback(
        () => <div className={styles['range-label']}>{formatAmountShortText(borrowersCount, '')}</div>,
        [borrowersCount]
    );

    const averageBorrowAmountLabel = useCallback(
        () => <div className={styles['range-label']}>{formatAmountShortText(averageBorrowAmount)}</div>,
        [averageBorrowAmount]
    );

    return (
        <SectionPart className={className}>
            <div className={styles['range-container']}>
                <div className={styles['calculator-label']}>Количество целевых заемщиков</div>
                <Range
                    withoutBorder
                    range={BORROWERS_COUNT_RANGE_CONFIG}
                    value={borrowersCount}
                    onChange={didBorrowersCountChanged}
                    editable
                    editorConfig={BORROWERS_COUNT_EDITOR_CONFIG}
                    onEditComplete={borrowersCountRangeEditorHandler}
                    labelRender={borrowersLabel}
                    lineClassName={styles['range-line']}
                />
            </div>
            <div className={styles['range-container']}>
                <div className={styles['calculator-label']}>Средняя сумма займа</div>
                <Range
                    withoutBorder
                    range={AVERAGE_BORROW_AMOUNT_RANGE_CONFIG}
                    value={averageBorrowAmount}
                    onChange={didAverageBorrowAmountChanged}
                    editable
                    editorConfig={FieldAmountValueConverter}
                    onEditComplete={averageBorrowAmountRangeEditorHandler}
                    labelRender={averageBorrowAmountLabel}
                    lineClassName={styles['range-line']}
                />
            </div>
            <div className={styles['result-container']}>
                <PropertyItem
                    className={styles['result']}
                    noPadding
                    title={(
                        <div className={buildClassNames(styles, ['calculator-label', 'calculator-label--large'])}>
                            Ожидаемый доход
                        </div>
                    )}
                    value={incomeValue}
                />
            </div>
        </SectionPart>
    );
};

export default connectFunctionalComponent(PartnerCalculatorFormCard, {
    dispatch: {
        sendEvent,
    },
}) as React.FC<IProps>;
