import { ISectionBasedProps } from '@app/models/common/common';
import { MainPageFeaturesSectionApiModel } from '@app/models/mainPage/mainPage.';
import styles from './AdvantagesFeature.module.scss';
import AdvantageCard from './AdvantageCard/AdvantageCard';
import AdvantagesSlider from './AdvantagesSlider/AdvantagesSlider';

type Props = ISectionBasedProps<MainPageFeaturesSectionApiModel>

const AdvantagesFeature : React.FC<Props> = props => {
    const { section } = props;

    return (
        <div className={styles['feature']}>
            <h2 className={styles['feature-title']}>{section.title}</h2>
            <div className={styles['cards']}>
                {section.blocks.map((block, index) => (
                    <AdvantageCard
                        key={block.id}
                        text={block.title}
                        imageSrc={block.image}
                        backgroundFilter={index !== 0}
                        wide={index === 0}
                    />
                ))}
            </div>
            <div className={styles['slider-wrapper']}>
                <AdvantagesSlider showNextSlide>
                    {section.blocks.map((block, index) => (
                        <AdvantageCard
                            key={block.id}
                            text={block.title}
                            imageSrc={block.image}
                            backgroundFilter={index !== 0}
                            wide={index === 0}
                        />
                    ))}
                </AdvantagesSlider>
            </div>
        </div>
    );
};

export default AdvantagesFeature;

