import { FC } from 'react';
import { IPartnersFeatureBlockApiModel } from '@app/models/partners/partners';
import styles from './OnlineFeature.module.scss';
import HoursIcon from '@app/icons/24h-feature.svg';
import AgreementIcon from '@app/icons/agreement-feature.svg';
import OnlineFeatureCard, { OnlineFeatureCardType } from './OnlineFeatureCard/OnlineFeatureCard';

interface Props {
    sectionBlocks: Record<string, IPartnersFeatureBlockApiModel>;
    title?: string;
    description?: string;
}

const OnlineFeature: FC<Props> = props => {
    const {
        sectionBlocks,
        title,
        description,
    } = props;

    return (
        <div className={styles['feature-wrapper']}>
            <div className={styles['feature-info']}>
                <h2 className={styles['feature-title']}>{title}</h2>
                <p className={styles['feature-description']}>{description}</p>
            </div>
            <div className={styles['feature-content']}>
                {Object.entries(sectionBlocks).map(([key, sectionBlock]) => (
                    <OnlineFeatureCard
                        className={styles['card']}
                        sectionBlock={sectionBlock}
                        type={key === 'personalManager' ? OnlineFeatureCardType.LONG : OnlineFeatureCardType.DEFAULT}
                        imageSrc={key === 'onlineDocuments' ? AgreementIcon : HoursIcon}
                        key={key}
                    />
                ))}
            </div>
        </div>
    );
};

export default OnlineFeature;
