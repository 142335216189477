import React from 'react';
import styles from './BorrowersCalculatorApply.module.scss';
import Image from '@app/components/server/Image/Image';
import BecomeBorrowerForm from '@app/components/client/borrowers/BecomeBorrowerForm/BecomeBorrowerForm';

const BorrowersCalculatorApply = () => (
    <div className={styles['container']}>
        <div>
            <h3 className={styles['title']}>Подайте заявку на займ</h3>
            <div className={styles['text']}>
                И получите решение
                за 3 минуты по 2-м документам
                онлайн, на счет в любом банке.
            </div>
        </div>
        <BecomeBorrowerForm containerClassName={styles['apply-button-container']} />
        <div className={styles['image']}>
            <Image src="/assets/lines.svg" alt="" fill />
        </div>
    </div>
);

export default BorrowersCalculatorApply;