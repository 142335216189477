import {
    PMT,
    PPMT,
    IPMT,
} from '@handsontable/formulajs';
import { RatingType } from '@frontend/jetlend-core/src/models/rating';
import { IRatingGroupInfo } from '@app/models/autoinvest';
import {
    ICalculatorState,
    ICreditCalculatorResult,
} from '@app/models/borrowers/borrowers';

/**
 * An array representing the names of the months in Russian.
 *
 * @type {Array<string>}
 */
export const MONTHS: Array<string> = [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
];

/**
 * Represents the result of an interest rate calculation.
 * @interface
 */
interface IResultInterestRate {
    max: number;
    min: number;
}

/**
 * Retrieves the interest rate range for a specific rating.
 *
 * @param {IRatingGroupInfo[]} rates - An array of rating group information.
 * @param {RatingType} rating - The specific rating to retrieve the interest rate for.
 * @return {IResultInterestRate} - An object containing the minimum and maximum interest rate for the given rating.
 *                                Returns { max: 0, min: 0 } if the rates array is empty or null.
 */
export function getInterestRate(rates: IRatingGroupInfo[], rating: RatingType): IResultInterestRate {
    if (!rates) {
        return {
            max: 0,
            min: 0,
        };
    }

    const ratingsInfo = rates.flatMap(group => group.records);
    const ratingEntry = ratingsInfo.find(item => item.rating === rating);
    if (!ratingEntry) {
        return {
            max: 0,
            min: 0,
        };
    }

    const {
        min_interest_rate,
        max_interest_rate,
    } = ratingsInfo.find(item => item.rating === rating);

    return {
        max: max_interest_rate,
        min: min_interest_rate,
    };
}

/**
 * Calculate credit details based on the given configuration and rating groups information.
 *
 * @param {ICalculatorState} config - The configuration object for credit calculation.
 * @param {IRatingGroupInfo[]} rates - The array of rating group information.
 * @returns {ICreditCalculatorResult} - The credit calculator result object.
 */
export const creditCalculator = (config: ICalculatorState, rates: IRatingGroupInfo[]): ICreditCalculatorResult => {
    // ЧЕКБОКСЫ
    const checkCompanyAge =  config.companyAge; // Срок регистрации компании
    const checkCreditHist = config.creditHistory; // Кредитная история бизнеса и бенефициаров
    const checkStability = config.stableRevenue;  // Стабильность поступления выручки
    const checkNumberEmployees = config.employeeAmount; // Численность сотрудников
    // КОНЕЦ ЧЕКБОКСЫ

    const clientGetMoneyPerMonth = config.revenueAmount; // выручка
    let probab; // вероятность кредита
    let percAprFrom; // Ожидаемая ставка от
    let percAprTo; // Ожидаемая ставка до

    if (checkCompanyAge === false || checkCreditHist === false || checkStability === false ) { // если плохая кредитная история и нестабильное поступление выручки
        const perc = getInterestRate(rates, 'C');
        probab = 10;
        percAprFrom = perc.min;
        percAprTo = perc.max;
    } else {
        if (clientGetMoneyPerMonth >= 0 && clientGetMoneyPerMonth < 1000000) {
            const perc = getInterestRate(rates, 'C');
            probab = 10;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        } else if (clientGetMoneyPerMonth >= 1000000 && clientGetMoneyPerMonth < 4000000) {
            const perc = getInterestRate(rates, 'BB');
            probab = 85;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        } else if (clientGetMoneyPerMonth >= 4000000 && clientGetMoneyPerMonth < 6500000) {
            const perc = getInterestRate(rates, 'BB+');
            probab = 86;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        } else if (clientGetMoneyPerMonth >= 6500000 && clientGetMoneyPerMonth < 9000000) {
            const perc = getInterestRate(rates, 'BBB+');
            probab = 88;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        } else if (clientGetMoneyPerMonth >= 9000000 && clientGetMoneyPerMonth < 11500000) {
            const perc = getInterestRate(rates, 'A');
            probab = 89;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        } else if (clientGetMoneyPerMonth >= 11500000 && clientGetMoneyPerMonth < 14000000) {
            const perc = getInterestRate(rates, 'A+');
            probab = 91;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        } else if (clientGetMoneyPerMonth >= 14000000 && clientGetMoneyPerMonth < 16500000) {
            const perc = getInterestRate(rates, 'AA+');
            probab = 92;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        } else if (clientGetMoneyPerMonth >= 16500000 && clientGetMoneyPerMonth < 19000000) {
            const perc = getInterestRate(rates, 'AAA');
            probab = 94;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        } else if (clientGetMoneyPerMonth >= 19000000 && clientGetMoneyPerMonth <= 20000000) {
            const perc = getInterestRate(rates, 'AAA+');
            probab = 95;
            percAprFrom = perc.min;
            percAprTo = perc.max;
        }

        if (checkNumberEmployees && clientGetMoneyPerMonth > 500000) { // если Численность сотрудников более 10 человек и выручка не ниже 500 т.р. тогда процент может вырасти на 50 проц
            const probabNew = probab + 50;

            if (probabNew > 95) {
                probab = 95;
            } else {
                probab += 50;
            }
        }
    }

    if (checkNumberEmployees) { // если Численность сотрудников более 10 человек тогда процент может вырасти на 50 проц
        percAprFrom -= 0.05;
        percAprTo -= 0.05;
    }

    const minPerc = getInterestRate(rates, 'AAA+');
    if (percAprFrom < minPerc.min) {
        percAprFrom = minPerc.min;
        percAprTo = minPerc.max;
    }

    const sum = config.loanAmount; // Желаемая сумма кредита
    const rate = percAprFrom; // годовая ставка
    const payEveryDays = 30; // Погашение каждый день
    const bankYear = 360; // Банковский год
    const period = config.loanDuration; // Желаемый срок кредита
    const state = (payEveryDays / bankYear) * rate; // Ставка за период
    const sumMonthPay = -PMT(state, period, sum, 0, 0); // Ежемесячный платеж

    return {
        max_interest_rate_amount: percAprTo,
        min_interest_rate_amount: percAprFrom,
        probability_of_approval: probab,
        monthly_payment: sumMonthPay,
    };
};

interface IDataResult {
    debtResult: number[];
    percentResult: number[];
}

/**
 * Calculates the debt and percent results for a given loan configuration and interest rate.
 *
 * @param {Pick<ICalculatorState, 'loanDuration' | 'loanAmount'>} config - The loan configuration object containing the loan duration and loan amount.
 * @param {number} rate - The interest rate for the loan.
 * @returns {IDataResult} - The debt and percent results.
 */
const buildData = (config: Pick<ICalculatorState, 'loanDuration' | 'loanAmount'>, rate: number): IDataResult => {
    const debtResult = [];
    const percentResult = [];
    const payEveryDays = 30; // Погашение каждый день
    const bankYear = 360; // Банковский год

    const state = (payEveryDays / bankYear) * rate; // Ставка за период

    for (let i = 0; i < config.loanDuration; i++) {
        const amountPayment = -PPMT(state, i, config.loanDuration, config.loanAmount, 0); // Начисленные проценты
        const percMonth = -IPMT(state, i, config.loanDuration, config.loanAmount, 0); // Основной долг

        debtResult.push(+amountPayment.toFixed(0));
        percentResult.push(+percMonth.toFixed(0));
    }

    return {
        debtResult,
        percentResult,
    };
};

/**
 * Builds the series data for an Apex axis chart based on the given configuration and interest rate.
 *
 * @param config - The configuration object containing the loan duration and loan amount.
 * @param rate - The interest rate to be used for calculations.
 * @returns An array of series objects with name, data, and color properties.
 */
export const buildSeries = (config: Pick<ICalculatorState, 'loanDuration' | 'loanAmount'>, rate: number): ApexAxisChartSeries => {

    const {
        debtResult,
        percentResult,
    } = buildData(config, rate);

    return [
        {
            name: 'Основной долг',
            data: debtResult,
            color: '#46BFA5', // Predefined color for the 'Основной долг' series
        },
        {
            name: 'Проценты',
            data: percentResult,
            color: '#46BFA51A', // Predefined color for the 'Проценты' series
        },
    ];
};