
import {
    Department,
    IBasePerson,
} from '@app/models/aboutPlatform/aboutPlatform';

export const filterPersonsByDepartment = (department: Department, persons: IBasePerson[]) => {
    if (department === Department.ALL) {
        return persons;
    }

    return persons.filter(person => person.department === department);
};
