import { IBasePerson } from '@app/models/aboutPlatform/aboutPlatform';
import styles from './DirectorCard.module.scss';
import Image from '@app/components/server/Image/Image';
import PersonPopper, { PopoverVariant } from '../../PersonPopper/PersonPopper';

interface IProps {
    person: IBasePerson;
}

const DirectorCard = (props: IProps) => {
    const {
        person,
    } = props;
    return (
        <PersonPopper person={person} className={styles['popper']} variant={PopoverVariant.DIRECTOR}>
            <div className={styles['director-card']}>
                <div className={styles['photo']}>
                    {person.photo
                        ? (
                            <Image className={styles['image']} src={person.photo} alt={person.name} fill />
                        )
                        : (
                            <div className={styles['photo-placeholder']} />
                        )}
                </div>
                <div className={styles['description']}>
                    <span className={styles['name']}>{person.name}</span>
                    <span className={styles['position']}>{person.position}</span>
                </div>
            </div>
        </PersonPopper>
    );
};

export default DirectorCard;
