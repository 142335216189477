'use client';

import {
    FC,
    Suspense,
} from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import dynamic from 'next/dynamic';
import styles from './RevenueCard.module.scss';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import OffscreenLoader from '@app/components/server/ui/OffscreenLoader/OffscreenLoader';
import SectionExpandedArea from '@app/components/server/ui/SectionExpandedArea/SectionExpandedArea';

interface IProps {
    className?: string;
    titleText: string;
    descriptionText: string;
    revenueValue: string;
    revenueDescriptionText: string;
}

const LazyPartnersRevenueChart = dynamic(
    () => import('@app/components/client/partners/PartnerRevenueChart/PartnerRevenueChart'),
    { ssr: false }
);

const RevenueCard: FC<IProps> = props => {
    const {
        className,
        titleText,
        descriptionText,
        revenueValue,
        revenueDescriptionText,
    } = props;

    return (
        <SectionPart className={className}>
            <div className={styles['content']}>
                <div className={styles['title-wrapper']}>
                    <h3 className={styles['title']}>{titleText}</h3>
                </div>
                <div className={styles['description-wrapper']}>
                    <p className={buildClassNames(styles, ['description', 'description--top'])}>{descriptionText}</p>
                </div>
                <div className={styles['value-wrapper']}>
                    <p className={styles['revenue-value']}>{revenueValue}</p>
                </div>
                <div className={styles['revenue-value-description']}>
                    <p className={buildClassNames(styles, ['description', 'description--bottom'])}>
                        {revenueDescriptionText}
                    </p>
                </div>
                <div className={styles['chart-wrapper']}>
                    <OffscreenLoader>
                        {({
                            inView,
                            ref,
                        }) => (
                            <SectionExpandedArea ref={ref} className={styles['chart']}>
                                <Suspense fallback={<LoaderBlock />}>
                                    {inView && <LazyPartnersRevenueChart height={353} hideSizeSwitcher noBorder />}
                                </Suspense>
                            </SectionExpandedArea>
                        )}
                    </OffscreenLoader>
                </div>
            </div>
        </SectionPart>
    );
};

export default RevenueCard;
