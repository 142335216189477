import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import AdvantageIcon from '@app/icons/advantage-cup.svg';
import styles from './AdvantageCard.module.scss';
import Image from '@app/components/server/Image/Image';

interface IProps {
    text?: string;
    imageSrc?: string;
    backgroundFilter?: boolean;
    wide?: boolean;
}

const AdvantageCard : React.FC<IProps> = props => {
    const {
        text,
        imageSrc,
        backgroundFilter,
        wide,
    } = props;
    return (
        <div className={buildClassNames(styles, ['card', backgroundFilter && 'card--with-filter'])}>
            <Image className={styles['icon']} src={AdvantageIcon} alt="" width={24} height={24} />
            <div className={buildClassNames(styles, ['text', wide && 'wide'])}>{text}</div>
            <Image className={styles['background-image']} src={imageSrc} alt="" fill />
        </div>
    );
};

export default AdvantageCard;

