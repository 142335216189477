import {
    RATINGS_LIST,
    Rating,
} from '@app/models/rating';

import {
    IPartnerCalculatorFormValues,
    IPartnerCalculatorResult,
} from '@app/models/partners/partners';

export function ratingToId(rating: Rating): number {
    return RATINGS_LIST.indexOf(rating);
}

export function evaluateIncomes(
    values: IPartnerCalculatorFormValues
): IPartnerCalculatorResult {
    if (!values) {
        return null;
    }

    return {
        expectedIncome:
			values.borrowersCount * values.averageBorrowAmount * 0.02,
    };
}
