'use client';

import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { formatPercentExt } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { memo } from 'react';
import styles from './InvestmentCalculator.module.scss';
import { investorsJetLendStatisticsHandler } from '@app/ducks/investors/investors';

const getPercentConfig = (fractionDigits: number) => ({
    fractionDigits,
    addSpace: false,
});

export default memo(function InvestmentCalculatorNotes() {
    const [jetlendStats] = useSimpleApiHandler(investorsJetLendStatisticsHandler);
    const stocks = formatPercentExt(jetlendStats?.stocks_expected_interest_rate, getPercentConfig(1));
    const bond = formatPercentExt(jetlendStats?.bond_expected_interest_rate, getPercentConfig(1));
    const deposits = formatPercentExt(jetlendStats?.deposits_expected_interest_rate, getPercentConfig(1));
    const realEstates = formatPercentExt(jetlendStats?.real_estates_expected_interest_rate, getPercentConfig(0));
    const interestMin = formatPercentExt(jetlendStats?.min_interest_rate, getPercentConfig(1));
    const interestMax = formatPercentExt(jetlendStats?.max_interest_rate, getPercentConfig(1));

    return (
        <>
            <p className={styles['grey-text']}>
                1. Фактическая доходность может выходить за значения указанного диапазона.
            </p>
            <p className={styles['grey-text']}>
                2. Для расчета графика используются следующие значения средней ожидаемой годовой доходности:
                акции {stocks} (индекс МосБиржи),
                облигации {bond} (индекс IFX-Cbonds),
                депозит {deposits} (по данным ЦБ РФ),
                доходность жилой недвижимости в Москве {realEstates},
                краудлендинг в зависимости от уровня риска:
                от {interestMin} до {interestMax}.
            </p>
        </>
    );
});
