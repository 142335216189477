import React, {
    PropsWithChildren,
    useCallback,
} from 'react';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import { IConnectableInputProps } from '@frontend/jetlend-core/src/ui/inputs/common';
import Loader from '../../loaders/Loader';
import {
    buildClassNames,
    mergeClassNames,
} from '../../../utils/classNameUtils';

import styles from './toggleButton.module.scss';

// TODO Implement styles with more feedback
// - add any hover effects

export interface ToggleButtonProps extends IConnectableInputProps, PropsWithChildren {
    className?: string;
    size?: 'small' | 'normal';
    disabled?: boolean;
    loading?: boolean;
    asSwitch?: boolean;
    label?: string;
}

export const ToggleButton: React.FC<ToggleButtonProps> = (props: ToggleButtonProps) => {
    const {
        value,
        loading,
        disabled,
        size,
        onChange,
    } = props;

    const isSmall = size === 'small';

    const buttonClassName = buildClassNames(styles, [
        'toggle',
        isSmall && 'toggle--small',
        (value || props.asSwitch) && 'toggle--active',
        disabled && 'toggle--disabled',
        loading && 'toggle--loading',
    ]);

    const knobClassName = buildClassNames(styles, [
        'toggle-knob',
        isSmall && 'toggle-knob--small',
        value && 'toggle-knob--active',
        (value && isSmall) && 'toggle-knob--active--small',
        (value || props.asSwitch) && 'toggle-knob--active-bg',
    ]);

    const didClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled || loading) {
            return;
        }

        onChange && onChange(!value);
    }, [ disabled, loading, onChange, value ]);

    const hasChildren = props.children && React.Children.count(props.children);
    const hasLabel = hasChildren ||
        (props.label && props.label?.length > 0);

    const button = (
        <button type="button" className={buttonClassName} onClick={didClick}>
            <div className={knobClassName}>
                {loading &&
                    <Loader
                        size="normal"
                        className={styles['toggle-knob__loader']}
                    />
                }
            </div>
        </button>
    );

    if (hasLabel) {
        return (
            <label className={mergeClassNames([ styles['container'], props.className ])}>
                <div className={styles['label']}>
                    {hasChildren ? props.children : props.label}
                </div>
                {button}
            </label>
        );
    }

    return button;
};

export const ToggleButtonField = connectToField(ToggleButton);

export default ToggleButton;