import Button, { ButtonSize } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { useCallback } from 'react';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './FilterButtonsBlock.module.scss';

export interface IFilterConfig {
    text: string;
    icon?: string | false;
    iconActive?: string | false;
}

interface IFilterButtonsBlockProps<T extends string, TConfig extends IFilterConfig> {
    elements: Record<T, TConfig>;
    filter: string;
    onElementSelect: (elementName: T) => () => void;
    containerClassName?: string;
    className?: string;
    buttonsSize?: ButtonSize;
}

const FilterButtonsBlock = <T extends string, TConfig extends IFilterConfig>(props: IFilterButtonsBlockProps<T, TConfig>) => {
    const {
        elements,
        onElementSelect,
        filter,
        containerClassName,
        className,
        buttonsSize = 'normal',
    } = props;

    const isActive = useCallback((elementName: T) => elementName === filter, [filter]);
    const getIcon = useCallback((config: TConfig, isActive = false) => {
        const icon = isActive ? config.iconActive : config.icon;
        return icon || '';
    }, []);

    return (
        Object.entries(elements).map(([ elementName, config ]: [T, TConfig]) => {
            const isButtonActive = isActive(elementName);
            return (
                <Button
                    className={mergeClassNames([styles['filter-button'], className])}
                    containerClassName={mergeClassNames([styles['button-container'], containerClassName])}
                    key={elementName}
                    text={config.text}
                    icon={getIcon(config, isButtonActive)}
                    outline={!isButtonActive}
                    onClick={onElementSelect(elementName)}
                    size={buttonsSize}
                />
            );
        })
    );
};

export default FilterButtonsBlock;
