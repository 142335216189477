import {
    BaseSectionWithBlocksAndDescription,
    HistoryFeatureType,
    IBaseSectionApiModel,
    IFeatureBlockApiModel,
} from '@app/models/common/common';

/**
 * Типы секции
 */
export enum InvestmentsSectionType {
    FEATURES = 'features',
    LINKS = 'links',
    CHART = 'chart',
    FAQ = 'faq',
    DOCUMENTS = 'documents',
    CONTACTS = 'contacts',
}

/**
 * Тип фича блоков на странице "Инвестиции"
 */
export type InvestmentsFeaturesSectionApiModel = BaseSectionWithBlocksAndDescription<IFeatureBlockApiModel<HistoryFeatureType>>

interface ICreditPortfolioColumn {
    /**
     * Дата начала квартала
     */
    start_date: string;
    /**
     * Значение по Y в млрд(0.5млрд, 1млрд, 1.5млрд и т.д.)
     */
    amount: number;
}

export type CreditPortfolioApiModel = ICreditPortfolioColumn[];

/**
 * Апи модель секции "Блок со ссылкой"
 */
export interface ILinkBlocksApiModel extends IBaseSectionApiModel {
    /**
     * Ссылки
     */
    links: ILinkBlockApiModel[];
}

/**
 * Апи модель блока со ссылкой
 */
export interface ILinkBlockApiModel {
    /**
     * Заголовок
     */
    title: string;
    /**
     * Описание
     */
    description: string;
    /**
     * Ссылка
     */
    url: string;
}

export interface IChartApiModel extends IBaseSectionApiModel {
    /**
     * Тип графика
     */
    id: InvestmentsChartType;
}

export enum InvestmentsChartType {
    /**
     * График "Наша доля на рынке за 12 месяцев"
     */
    MARKET_SHARE = 'market-share',
    /**
     * График "Сферы бизнеса и отрасли экономики, которым мы помогаем развиваться"
     */
    BUSINESS_AREAS = 'business-areas',
    /**
     * График "Рост кредитного портфеля платформы до 10 млрд. руб"
     */
    CREDIT_PORTFOLIO = 'credit-portfolio',
}


export enum IChartDataType {
    /**
     * Значение в валюте
     */
    AMOUNT = 'amount',
    /**
     * Абсолютное значение
     */
    COUNT = 'count',
}

export interface IChartDataApiModel {
    /**
     * Общее количество
     */
    total: number;
    /**
     * Тип основного значения на графике
     */
    type: IChartDataType;
    /**
     * Список слайсов
     */
    values: IChartValueApiModel[];
}

export interface IChartValueApiModel {
    /**
     * Название опции
     */
    title: string;
    /**
     * Процентное значение
     */
    part: number;
}


/**
 * Апи модель секции "Контакты"
 */
export interface IContactsApiModel extends IBaseSectionApiModel {
    /**
     * Сотрудник
     */
    contacts: IContactsEmployeeApiModel;
}

/**
 * Апи модель сотрудника в секции "Контакты"
 */
export interface IContactsEmployeeApiModel {
    /**
     * Фотография
     */
    photo: string;
    /**
     * Имя
     */
    name: string;
    /**
     * Должность
     */
    position: string;
    /**
     * email
     */
    email: string;
    /**
     * номер телефона
     */
    phone: string;
    /**
     * Ссылка на whatsapp
     */
    whatsapp: string;
    /**
     * Ссылка на telegram
     */
    telegram: string;
}
