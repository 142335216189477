'use client';

import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './DocumentsBlock.module.scss';
import { IDocument } from '@app/models/documents/documents';
import DocumentsFilter from '../DocumentsFilter/DocumentsFilter';
import DocumentsSearch from '../DocumentsSearch/DocumentsSearch';
import { updateDocuments } from '@app/ducks/documents/documents';
import DocumentsList from '../DocumentsList/DocumentsList';

interface IProps {
    documents: IDocument[];
    disableHeader?: boolean;
}

export default function DocumentsBlock({
    documents,
    disableHeader,
}: IProps) {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(updateDocuments(documents));
    }, [dispatch, documents]);

    return (
        <div className={styles['documents-block']}>
            {!disableHeader && (
                <div className={styles['block-header']}>
                    <DocumentsFilter className={styles['filter']} />
                    <DocumentsSearch />
                </div>
            )}
            <div className={styles['block-body']}>
                <DocumentsList />
            </div>
        </div>
    );
}
