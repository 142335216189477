// TODO: Change to numbers / Get from lib?

export type Rating =
    'A' | 'AA' | 'AAA' | 'A+' | 'AA+' | 'AAA+' |
    'B' | 'BB' | 'BBB' | 'B+' | 'BB+' | 'BBB+' |
    'C' | 'CC' | 'CCC' | 'C+' | 'CC+' | 'CCC+';

export const RATINGS_A: Rating[] = [
    'AAA+', 'AAA',
    'AA+', 'AA',
    'A+', 'A',
];

export const RATINGS_B: Rating[] = [
    'BBB+', 'BBB',
    'BB+', 'BB',
    'B+', 'B',
];

export const RATINGS_C: Rating[] = [
    'CCC+', 'CCC',
    'CC+', 'CC',
    'C+', 'C',
];

export const RATINGS_LIST: Rating[] = [
    ...RATINGS_A,
    ...RATINGS_B,
    ...RATINGS_C,
];