import React, { memo } from 'react';
import LayoutFooterSectionPortal from '@app/components/server/common/LayoutFooter/LayoutFooterSectionPortal';
import InvestmentCalculatorNotes from '@app/components/client/investors/InvestmentCalculator/InvestmentCalculatorNotes';

const InvestmentCalculatorFooter = memo(() => (
    <LayoutFooterSectionPortal sectionType="notes">
        <InvestmentCalculatorNotes />
    </LayoutFooterSectionPortal>
));

export default InvestmentCalculatorFooter;
