'use client';

import React, {
    PropsWithChildren,
    useCallback,
} from 'react';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './SectionPart.module.scss';
import {
    AnimationType,
    useAnimation,
} from '../animations';

type PaddingSize = 'normal' | 'big';

interface IProps extends PropsWithChildren  {
    withShadow?: boolean;
    className?: string;
    paddingSize?: PaddingSize;
    noRoot?: boolean;
    animation?: AnimationType;
    animationThreshold?: number;

    onClick?: () => void;
}

const SectionPart: React.FC<IProps> = props => {

    const {
        paddingSize = 'normal',
        noRoot = false,
        animation = 'none',
        onClick,
    } = props;

    const [ ref, animationClassName ] = useAnimation(animation, props.animationThreshold);

    const containerClassNames = mergeClassNames([
        buildClassNames(styles, [
            'container',
            !noRoot && 'root-container',
            props.withShadow && 'container--with-shadow',
            `padding-size__${paddingSize}`,
        ]),
        animationClassName,
        props.className,
    ]);

    const didClicked = useCallback(() => onClick && onClick(), [onClick]);

    return (
        <div
            ref={ref}
            className={containerClassNames}
            onClick={didClicked}
        >
            {props.children}
        </div>
    );
};

export default SectionPart;