import { splitNumberWithDigestGroups } from '@core/formatters/formatUtils';
import { parseFloatExt } from '@core/validators/common';
import { IFieldValueConverter } from './common';

/**
 * Конвертер значений для полей ввода натуральных чисел.
 * Преобразует введенное значение в число.
 * Запрещает ввод символов не относящихся к цифрам, не дает ввести знаки после запятой.
 *
 * @Category Fields
 */
export const NumberFieldConverter: IFieldValueConverter = {
    format: (value): any => {
        if (value && typeof value === 'number') {
            return splitNumberWithDigestGroups(value, 0);
        }

        return value;
    },
    parse: (text: string): any => {
        if (typeof text !== 'string') {
            return text;
        }

        let inputText = text;

        // Удаляем все невалидные символы
        inputText = inputText.replace(/[^0-9.,-]+/g, '');

        const value = parseFloatExt(inputText);
        // Сохраняем значение в текстовом виде для граничных случаев
        if (Number.isNaN(value) || value === undefined || value === null) {
            return inputText;
        }

        return value;
    },
};