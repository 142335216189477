'use client';

import React, {
    useMemo,
    useRef,
} from 'react';
import { useResizeObserver } from '@frontend/jetlend-web-ui/src/hooks/useResizeObserver';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryGroup,
    VictoryLabel,
} from 'victory';
import moment from 'moment';
import styles from './InvestmentsCreditPortfolioChart.module.scss';
import InvestmentsCreditPortfolioChartAxisTick from '@app/components/client/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChart/InvestmentsCreditPortfolioChartAxisTick/InvestmentsCreditPortfolioChartAxisTick';
import {
    EXTRA_SMALL_BREAKPOINT,
    getBarCornerRadius,
    getChartPadding,
    getColumnsOffset,
    getColumnsWidth,
    getDomainPadding,
    getFormattedData,
    getLabelDYValue,
    getLabelFontSize,
    getLabelTitle,
    getLastXAxisValue,
} from './InvestmentsCreditPortfolioChart.logic';
import { creditPortfolioChartHandler } from '@app/ducks/investments';
import { IChartApiModel } from '@app/models/investments/investments';

/**
 * Стиль текста
 */
const FONT_FAMILY = 'SF Pro Display, sans-serif';

// График кредитного портфеля
export default function InvestmentsCreditPortfolioChart({ title }: IChartApiModel) {
    const containerRef = useRef();
    // Ширина и высота графика для адаптивности
    const [width, height] = useResizeObserver(containerRef);
    const [data] = useSimpleApiHandler(creditPortfolioChartHandler);

    const formattedData = useMemo(() => {
        if (!data) {
            return;
        }

        return getFormattedData(data);
    }, [data]);

    const years: string[] = useMemo(() => {
        if (!formattedData) {
            return [];
        }

        return formattedData?.[0]?.map(item => moment(item.date).format('YYYY'));
    }, [formattedData]);

    const quartersCount = useMemo(() => {
        if (!formattedData) {
            return 0;
        }

        // Находим максимальное значение x (последний год)
        const maxYear = Math.max(...formattedData.flat().map(item => item.x));

        // Находим количество элементов с этим значением x (кварталы за последний год)
        const lastYearQuarters = formattedData.flat().filter(item => item.x === maxYear);

        // Возвращаем количество кварталов
        return lastYearQuarters.length;
    }, [formattedData]);

    return (
        <>
            <h2 className={styles['title']}>{title}</h2>
            <div className={styles['container']} ref={containerRef}>
                <svg
                    width="100%"
                    height="100%"
                >
                    <VictoryChart
                        width={width}
                        height={height}
                        padding={getChartPadding(width)}
                        domainPadding={{ x: getDomainPadding(width) }}
                        standalone={false}
                    >
                        {/* Ось X */}
                        <VictoryAxis
                            tickValues={[0.5, 1, 1.5, 2, 2.5, 3, 3.5, ...getLastXAxisValue(quartersCount)]}
                            tickFormat={['']}
                            style={{
                                axis: {
                                    stroke: '#00000096',
                                },
                            }}
                            tickLabelComponent={(
                                <InvestmentsCreditPortfolioChartAxisTick
                                    years={years}
                                    width={width}
                                    quartersCount={quartersCount}
                                />
                            )}
                        />
                        {/* Ось Y */}
                        <VictoryAxis
                            style={{
                                axis: { stroke: 'transparent' },
                                grid: {
                                    stroke: ({ tickValue }: any) => tickValue === 0 ? 'transparent' : '#00000047',
                                    strokeDasharray: '4',
                                },
                            }}
                            dependentAxis
                            tickValues={[0, 2, 4, 6, 8, 10]}
                            tickLabelComponent={<InvestmentsCreditPortfolioChartAxisTick dependentAxis width={width} />}
                        />
                        {/* Группировка колонок */}
                        <VictoryGroup
                            offset={getColumnsOffset(width)}
                            style={{
                                data: {
                                    width: getColumnsWidth(width),
                                },
                            }}
                            labelComponent={(
                                <VictoryLabel
                                    y={height}
                                    dy={getLabelDYValue(width)}
                                    style={{
                                        fill: 'black',
                                        fontSize: getLabelFontSize(width),
                                        fontWeight: width <= EXTRA_SMALL_BREAKPOINT ? 400 : 600,
                                        fontFamily: FONT_FAMILY,
                                    }}
                                />
                            )}
                        >
                            {/* Получаем колонки для каждого квартала */}
                            {formattedData?.map((item, index) => (
                                <VictoryBar
                                    key={index}
                                    cornerRadius={{ top: getBarCornerRadius(width) }}
                                    data={item}
                                    labels={({ datum }) => getLabelTitle(width, datum.title, quartersCount, datum.x)}
                                    style={{
                                        data: { fill: ({ datum }) => datum.color },
                                    }}
                                />
                            ))}
                        </VictoryGroup>
                    </VictoryChart>
                </svg>
            </div>
        </>
    );
};
