'use client';

import { ISectionBasedProps } from '@app/models/common/common';
import HistoryFeature from '@app/components/server/common/HistoryFeature/HistoryFeature';
import { getBlockType } from './InvestmentsFeaturesSection.logic';
import { InvestmentsFeaturesSectionApiModel } from '@app/models/investments/investments';

export default function InvestmentsFeaturesSection(props: ISectionBasedProps<InvestmentsFeaturesSectionApiModel>) {
    const { section } = props;
    const sectionType = getBlockType(section.blocks[0]);
    const config = {
        'history': <HistoryFeature {...props} />,
        'null': null,
    };

    return config[sectionType];
};
