import departmentUnderwritingIcon from '@app/icons/department-underwriting.svg';
import departmentUnderwritingIconActive from '@app/icons/department-underwriting--active.svg';
import departmentInvestorsIcon from '@app/icons/department-investors.svg';
import departmentInvestorsIconActive from '@app/icons/department-investors--active.svg';
import departmentDevelopmentIcon from '@app/icons/department-development.svg';
import departmentDevelopmentIconActive from '@app/icons/department-development--active.svg';
import departmentBorrowerIcon from '@app/icons/department-borrower.svg';
import departmentBorrowerIconActive from '@app/icons/department-borrower--active.svg';
import departmentRisksIcon from '@app/icons/department-risks.svg';
import departmentRisksIconActive from '@app/icons/department-risks--active.svg';
import departmentFinanceIcon from '@app/icons/department-finance.svg';
import departmentFinanceIconActive from '@app/icons/department-finance--active.svg';
import departmentHrIcon from '@app/icons/department-hr.svg';
import departmentHrIconActive from '@app/icons/department-hr--active.svg';
import departmentMarketingIcon from '@app/icons/department-marketing.svg';
import departmentMarketingIconActive from '@app/icons/department-marketing--active.svg';
import departmentLegalIcon from '@app/icons/department-legal.svg';
import departmentLegalIconActive from '@app/icons/department-legal--active.svg';
import { Department } from '@app/models/aboutPlatform/aboutPlatform';

export interface IDepartmentConfig {
    text: string;
    icon: string | false;
    iconActive: string | false;
}

export const DEPARTMENT_CONFIGURATION: Record<Department, IDepartmentConfig> = {
    [Department.ALL]: {
        text: 'Все департаменты',
        icon: false,
        iconActive: false,
    },
    [Department.RISKS]: {
        text: 'Департамент рисков и DS',
        icon: departmentRisksIcon,
        iconActive: departmentRisksIconActive,
    },
    [Department.UNDERWRITING]: {
        text: 'Департамент андеррайтинга',
        icon: departmentUnderwritingIcon,
        iconActive: departmentUnderwritingIconActive,
    },
    [Department.INVESTORS]: {
        text: 'Департамент продаж инвесторам',
        icon: departmentInvestorsIcon,
        iconActive: departmentInvestorsIconActive,
    },
    [Department.BORROWER]: {
        text: 'Департамент продаж заемщикам',
        icon: departmentBorrowerIcon,
        iconActive: departmentBorrowerIconActive,
    },
    [Department.MARKETING]: {
        text: 'Департамент маркетинга',
        icon: departmentMarketingIcon,
        iconActive: departmentMarketingIconActive,
    },
    [Department.DEVELOPMENT]: {
        text: 'Департамент разработки',
        icon: departmentDevelopmentIcon,
        iconActive: departmentDevelopmentIconActive,
    },
    [Department.FINANCE]: {
        text: 'Департамент финансов',
        icon: departmentFinanceIcon,
        iconActive: departmentFinanceIconActive,
    },
    [Department.HR]: {
        text: 'Департамент рекрутмента',
        icon: departmentHrIcon,
        iconActive: departmentHrIconActive,
    },
    [Department.LEGAL]: {
        text: 'Юридический департамент',
        icon: departmentLegalIcon,
        iconActive: departmentLegalIconActive,
    },
};

