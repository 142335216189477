import React, {
    useEffect,
    useState,
} from 'react';

export interface AnimatedCounterProps {
    /**
     * Initial value for counter.
     */
    initialValue?: number;
    /**
     * Final value for counter.
     */
    value: number;
    /**
     * Counter operation time.
     */
    duration?: number;
    /**
     * Wrapper over the displayed value.
     */
    render?: (value: number) => React.ReactNode|JSX.Element;
}

const TIMER_STEP_MS = 25;
const ANIMATION_DURATION = 400;

const AnimatedCounter: React.FC<AnimatedCounterProps> = ({
    duration = ANIMATION_DURATION,
    initialValue,
    value,
    render,
}) => {
    const [valueToDisplay, setValueToDisplay] = useState(() => initialValue ?? value);

    useEffect(() => {
        const intervalId = evaluateAnimation(value, valueToDisplay);

        return () => {
            clearInterval(intervalId);
        };
    }, [value]);

    const evaluateAnimation = (value: number, prevValue: number) => {
        const delta = value - prevValue;

        if (Math.abs(delta) <= 0.0001) {
            return undefined;
        }

        const intervalId = setInterval(() => {
            setValueToDisplay(prevValueToDisplay => {
                const dv = delta / duration * TIMER_STEP_MS;

                if (Math.abs(prevValueToDisplay - value) < Math.abs(dv)) {
                    clearInterval(intervalId);

                    return value;
                }

                return prevValueToDisplay + dv;
            });
        }, TIMER_STEP_MS);

        return intervalId;
    };


    if (render) {
        return render(valueToDisplay) as never;
    }

    return valueToDisplay as never;
};

export default AnimatedCounter;
