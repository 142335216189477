import {
    BaseSectionWithBlocks,
    IBaseSectionApiModel,
} from '../common/common';

export enum PartnersSectionType {
    FEATURES = 'features',
    CONDITIONS = 'conditions',
    CALCULATOR = 'calculator',
    FAQ = 'faq',
}

export interface IPartnerCalculatorFormValues {
    borrowersCount: number;
    averageBorrowAmount: number;
}

export interface IPartnerCalculatorResult {
    expectedIncome: number;
}

export interface IConditionsSectionApiModel extends IBaseSectionApiModel {
    items: IConditionItemApiModel[];
}

export interface IConditionItemApiModel {
    title: string;
    description: string;
}

export interface IPartnersSectionBlockApiModel {
    title: string;
    value?: string;
    description?: string;
    value_description?: string;
}

export interface PartnersFeaturesSectionApiModel extends BaseSectionWithBlocks<IPartnersFeatureBlockApiModel> {
    description: string;
}

export interface IPartnersFeatureBlockApiModel extends IPartnersSectionBlockApiModel {
    id: IPartnersFeatureType;
}

export enum IPartnersFeatureType {
    REVENUE = 'revenue',
    AUTOWITHDRAW = 'autowithdraw',
    BEST_PRODUCT = 'best-product',
    ONLINE_DOCUMENTS = 'online-documents',
    ONE_DAY_REGISTRATION = 'one-day-registration',
    PERSONAL_MANAGER = 'personal-manager',
    ALL_IN_ONE_CABINET = 'all-in-one-cabinet',
    REFERRAL_LINKS = 'referral-links',
    API_INTEGRATIONS = 'api-integrations',
}

export interface IPartnersRevenuePointApiModel {
    revenue_percentage: number;
}

export interface IPartnersRevenueStatisticsApiModel {
    max_percentage: number;
}
