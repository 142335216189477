import React, { forwardRef } from 'react';
import { cnx } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './sectionExpandedArea.module.scss';

export type IProps = React.PropsWithChildren & {
    className?: string;
};

const SectionExpandedArea = forwardRef<HTMLDivElement, IProps>(( {
    children,
    className,
}, ref ) => (
    <div ref={ref} className={cnx([ styles['container'], className ])}>
        {children}
    </div>
));

export default SectionExpandedArea;
