import {
    InView,
    IntersectionObserverProps,
} from 'react-intersection-observer';

export interface IProps {
    children: IntersectionObserverProps['children'];
}

const OffscreenLoader: React.FC<IProps> = ({ children }) => (
    <InView
        rootMargin="300px 0px"
        threshold={0}
        triggerOnce
    >
        {children}
    </InView>
);

export default OffscreenLoader;
