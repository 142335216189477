import {
    isFloatValue,
    parseFloatExt,
} from '@frontend/jetlend-core/src/validators';
import {
    EditorHandler,
    IEditorConfig,
    NumberValuesRange,
} from './Range';

// TODO Переименовать т.к. не являются хуком
export function useRangeNumberEditorHandler(rangeConfig: NumberValuesRange, editorConfig?: IEditorConfig): EditorHandler {
    // TODO Refactor to pass editor config from Range component directly
    return v => new Promise((resolve, reject) => {
        const {
            min,
            max,
            labelFormatter,
        } = rangeConfig;

        const step = typeof editorConfig?.minStep === 'number'
            ? editorConfig.minStep
            : rangeConfig.step;

        const parser = parseFloatExt;

        const parsedNumber: number = parser(v);
        if (typeof parsedNumber === 'number' && Number.isNaN(parsedNumber)) {
            resolve({
                value: min,
                title: labelFormatter(min),
            });
        }

        const clampedNumber = Math.min(max, Math.max(parsedNumber, min));
        const roundClampedNumber = Math.round(clampedNumber / step) * step;


        if (isFloatValue(v)) {
            resolve({
                value: roundClampedNumber,
                title: labelFormatter(roundClampedNumber),
            });
        }

        reject();
    });
}