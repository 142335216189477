'use client';

import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    isMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import {
    Suspense,
    useMemo,
} from 'react';
import LoaderBlock from '@frontend/jetlend-web-ui/src/ui/loaders/LoaderBlock';
import dynamic from 'next/dynamic';
import { ISectionBasedProps } from '@app/models/common/common';
import BecomeInvestorForm from '@app/components/client/investors/BecomeInvestorForm/BecomeInvestorForm';
import {
    IMainPageFeatureType,
    MainPageFeaturesSectionApiModel,
} from '@app/models/mainPage/mainPage.';
import styles from './YieldRateFeature.module.scss';
import SectionPart from '@app/components/server/ui/SectionPart/SectionPart';
import OffscreenLoader from '@app/components/server/ui/OffscreenLoader/OffscreenLoader';
import SectionExpandedArea from '@app/components/server/ui/SectionExpandedArea/SectionExpandedArea';

type Props = ISectionBasedProps<MainPageFeaturesSectionApiModel>

const LazyInvestorsYieldChart = dynamic(() => import('@app/components/client/investors/InvestorsYieldChart/InvestorsYieldChart'), { ssr: false });

const YieldRateFeature: React.FC<Props> = props => {
    const { section } = props;

    const profitability = useMemo(() => section.blocks.find(block => block.id === IMainPageFeatureType.PROFITABILITY), [section]);

    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);
    const isTabletScreen = isTablet(screenWidth);

    const yieldContainerStyles = mergeClassNames([
        styles['profitability__chart-container'],
    ]);

    const profitabilityChartClassName = buildClassNames(styles, [
        'chart',
        'profitability__chart',
    ]);

    // TODO Order blocks based on API response

    return (
        <div className={styles['feature']}>
            <h2 className={styles['feature-title']}>{section.title}</h2>
            {profitability && (
                <SectionPart
                    className={styles['profitability']}
                // animation="slide-up"
                // animationThreshold={0}
                >
                    <div className={yieldContainerStyles}>
                        <h3 className={buildClassNames(styles, ['heading', 'profitability__heading'])}>{profitability.title}</h3>
                        <OffscreenLoader>
                            {({
                                inView,
                                ref,
                            }) => (
                                <SectionExpandedArea ref={ref} className={profitabilityChartClassName}>
                                    <Suspense fallback={<LoaderBlock />}>
                                        {inView && (
                                            <LazyInvestorsYieldChart
                                                height={isMobileScreen || isTabletScreen ? 250 : 330}
                                                hideSizeSwitcher
                                                noBorder
                                            />
                                        )}
                                    </Suspense>
                                </SectionExpandedArea>
                            )}
                        </OffscreenLoader>

                        <div className={styles['profitability__description-container']}>
                            <p className={styles['info-value']}>
                                {profitability.value}
                            </p>
                            <p className={styles['profitability__info-description']}>
                                {profitability.description}
                            </p>
                        </div>
                    </div>
                </SectionPart>
            )}
            <div className={styles['button-wrapper']}>
                <BecomeInvestorForm type="button" buttonText="Стать инвестором" />
            </div>
        </div>
    );
};

export default YieldRateFeature;