import React from 'react';
import styles from './propertiesGrid.module.scss';
import { buildClassNames } from '@ui/utils/classNameUtils';

export interface Props extends React.PropsWithChildren {
    /**
     * Добавляет разделитель в виде border dash между элементами
     */
    divider?: boolean;
    /**
     * Устанавливает контейнеру justify-content
     */
    justify?: 'start' | 'between';
    /**
     * Устанавливает дочерним элементам justify-content
     */
    justifyContent?: 'flex-start' | 'center';
}

const PropertiesGrid: React.FC<Props> = ({
    justify,
    justifyContent = 'center',
    divider = false,
    children,
}) => {

    const containerClassNames = buildClassNames(styles, [
        'container',
        `container-content--${justifyContent}`,
        justify && `container--${justify}`,
        divider && 'container--divider',
    ]);

    return (
        <div className={containerClassNames}>
            {children}
        </div>
    );
};

export default PropertiesGrid;