import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    useRouter,
    useSearchParams,
} from 'next/navigation';

type HookReturnType = [
    string,
    (text: string, reload?: boolean) => void,
    () => void
];

/**
 * Функция для получения текущего url (вместе с query параметрами)
 */
export const getCurrentHref = () => typeof window !== 'undefined' && new URL(window?.location?.href);

/**
 * Функция для установки url без перезагрузки страницы
 */
export const setHrefWithoutReload = (url: URL) => typeof window !== 'undefined' && window.history.pushState(null, '', url.toString());

/**
 * Добавляет query параметры в url
 * @param text текст query параметра
 * @param queryName query параметр
 * @category Hooks
 * @returns Содержимое по текущему query параметру, изменение и удаление query параметра
 * @example
 * const [currentParams, setParam, clearParam ] = useQueryParams('search');
 */
export default function useQueryParams(queryName: string): HookReturnType {
    const [currentParams, setCurrentParams] = useState('');
    const router = useRouter();
    const searchParams = useSearchParams();

    const setParam = useCallback((text: string, reload = true) => {
        const url = getCurrentHref();
        const searchText = text;

        if (searchText.trim() === '') {
            url.searchParams.delete(`${queryName}`);
            router.replace(url.toString());

            // По-умолчанию логика прежняя
            if (reload) {
                router.replace(url.toString());
            } else {
                // При наличии флага reload = false установить query параметр без перезагрузки страницы
                setHrefWithoutReload(url);
            }
        } else {
            url.searchParams.set(`${queryName}`, searchText);

            // По-умолчанию логика прежняя
            if (reload) {
                router.replace(url.search);
            } else {
                // При наличии флага reload = false установить query параметр без перезагрузки страницы
                setHrefWithoutReload(url);
            }
        }
    }, []);

    const clearParam = useCallback(() => {
        const url = getCurrentHref();

        if (url.searchParams.has(`${queryName}`)) {
            url.searchParams.delete(`${queryName}`);
            router.replace(url.toString());
        }
    }, []);

    useEffect(() => {
        const url = getCurrentHref();
        url && setCurrentParams(url.searchParams.get(`${queryName}`));
    }, [searchParams]);

    return [
        currentParams,
        setParam,
        clearParam,
    ];
}
