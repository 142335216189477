import Image from '@app/components/server/Image/Image';
import styles from './Condition.module.scss';

interface IProps {
    title?: string;
    description?: string;
    value?: string;
    valueDescription?: string;
    imageSrc?: string;
}

const Condition : React.FC<IProps> = props => {
    const {
        title,
        description,
        value,
        valueDescription,
        imageSrc,
    } = props;

    return (
        <div className={styles['condition']}>
            <div className={styles['title-wrapper']}>
                <h3 className={styles['title']}>{title}</h3>
            </div>
            <div className={styles['description-wrapper']}>
                <p className={styles['description']}>{description}</p>
            </div>
            <div className={styles['value-description-wrapper']}>
                <span className={styles['value-description']}>{valueDescription}</span>
            </div>
            <div className={styles['value-wrapper']}>
                {value && <span className={styles['value']}>{value}</span>}
                {!value && imageSrc && (
                    <div className={styles['image-wrapper']}>
                        <Image className={styles['image']} src={imageSrc} alt="" fill />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Condition;

