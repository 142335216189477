'use client';

import React, {
    useCallback,
    useMemo,
} from 'react';
import Range, {
    IEditorConfig,
    NumberValuesRange,
} from '@frontend/jetlend-web-ui/src/ui/inputs/Range/Range';
import { formatAmountShortText } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { useRangeNumberEditorHandler } from '@frontend/jetlend-web-ui/src/ui/inputs/Range/utils';
import { withDebounce } from '@frontend/jetlend-core/src/async/debounce';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import { numWordForm } from '@frontend/jetlend-core/src/utils/langUtils';
import ToggleButton from '@frontend/jetlend-web-ui/src/ui/inputs/ToggleButton/ToggleButton';
import { FieldAmountValueConverter } from '@frontend/jetlend-core/src/fields/amountFieldConverter';
import styles from './BorrowersCalculatorForm.module.scss';
import { sendEvent } from '@app/ducks/common/analytics';
import { calculatorHandler } from '@app/ducks/borrowers/borrowers';
import { ICalculatorState } from '@app/models/borrowers/borrowers';
import { IAccountStatusDescription } from '@app/models/account';
import { accountStatusesHandler } from '@app/ducks/account';

const REVENUE_AMOUNT_RANGE_CONFIG: NumberValuesRange = {
    min: 0,
    max: 20000000,
    step: 100000,
    labelFormatter: v => formatAmountShortText(v),
};

const SWITCHERS_CONFIG = [
    {
        id: 'companyAge',
        label: 'Компания зарегистрирована более 12 месяцев назад',
    },
    {
        id: 'creditHistory',
        label: 'Кредитная история бизнеса и бенефициаров положительная или нейтральная',
    },
    {
        id: 'stableRevenue',
        label: 'Стабильное поступление выручки каждый месяц',
    },
    {
        id: 'employeeAmount',
        label: 'Более 10 сотрудников числится в компании',
    },
];

interface IProps {
    title: string;
}

type Props = IProps & {
    sendEvent: typeof sendEvent;
    state: ICalculatorState;
    updateState: typeof calculatorHandler.update;
    statuses: IAccountStatusDescription[];
}

const BorrowersCalculatorForm = (props: Props) => {
    const {
        title,
        state,
        updateState,
        statuses,
    } = props;

    const TERM_EDITOR_CONFIG: IEditorConfig = {
        ...FieldAmountValueConverter,
        postfix: 'мес.',
    };

    const {
        minTrancheAmount = 500000,
        maxTrancheAmount = 39000000,
        maxTerm = 36,
    } = useMemo(() => {
        if (!statuses || statuses.length === 0) {
            return {};
        }

        const firstStatus = statuses[0];
        const lastStatus = statuses[statuses.length - 1];

        return {
            minTrancheAmount: firstStatus.min_tranche_amount,
            maxTrancheAmount: lastStatus.max_amount,
            maxTerm: lastStatus.max_tranche_term,
        };
    }, [statuses]);

    const LOAN_AMOUNT_RANGE_CONFIG = useMemo<NumberValuesRange>(() => ({
        min: minTrancheAmount,
        max: maxTrancheAmount,
        step: 10000,
        labelFormatter: v => formatAmountShortText(v),
    }), [minTrancheAmount, maxTrancheAmount]);

    const TERM_RANGE_CONFIG = useMemo<NumberValuesRange>(() => ({
        min: 1,
        max: maxTerm,
        step: 1,
        labelFormatter: v => numWordForm(v, 'month'),
    }), [maxTerm]);



    const didChanged = useMemo(() => {
        const debouncedSendEvent = withDebounce(sendEvent);

        return () => {
            debouncedSendEvent('credit-calculator--changed');
        };
    }, []);

    const didUpdatedState = useCallback((id: string, value: any) => {
        updateState({
            [id]: value,
        });
        didChanged();
    }, []);

    const loanAmountRangeEditorHandler = useMemo(
        // useRangeNumberEditorHandler is not a React Hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        () => useRangeNumberEditorHandler(LOAN_AMOUNT_RANGE_CONFIG, { minStep: 1000 }),
        [LOAN_AMOUNT_RANGE_CONFIG]);

    const loanDurationRangeEditorHandler = useMemo(
        // useRangeNumberEditorHandler is not a React Hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        () => useRangeNumberEditorHandler(TERM_RANGE_CONFIG),
        [TERM_RANGE_CONFIG]);

    const revenueAmountRangeEditorHandler = useMemo(
        // useRangeNumberEditorHandler is not a React Hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        () => useRangeNumberEditorHandler(REVENUE_AMOUNT_RANGE_CONFIG, { minStep: 1000 }),
        []);

    return (
        <div className={styles['container']}>
            <h2 className={styles['title']}>{title}</h2>
            <div className={styles['range-container']}>
                <div className={styles['form-label']}>
                    Желаемая сумма займа
                </div>
                <Range
                    withoutBorder
                    range={LOAN_AMOUNT_RANGE_CONFIG}
                    value={state.loanAmount}
                    onChange={value => didUpdatedState('loanAmount', value)}
                    editable
                    editorConfig={FieldAmountValueConverter}
                    onEditComplete={loanAmountRangeEditorHandler}
                    labelRender={() => <div className={styles['range-label']}>{formatAmountShortText(state.loanAmount)}</div>}
                    lineClassName={styles['range-line']}
                />
            </div>
            <div className={styles['range-container']}>
                <div className={styles['form-label']}>
                    Желаемый срок займа
                </div>
                <Range
                    withoutBorder
                    range={TERM_RANGE_CONFIG}
                    value={state.loanDuration}
                    onChange={value => didUpdatedState('loanDuration', value)}
                    editable
                    editorConfig={TERM_EDITOR_CONFIG}
                    onEditComplete={loanDurationRangeEditorHandler}
                    labelRender={() => <div className={styles['range-label']}>{numWordForm(state.loanDuration, 'month')}</div>}
                    lineClassName={styles['range-line']}
                />
            </div>
            <div className={styles['range-container']}>
                <div className={styles['form-label']}>
                    Среднемесячная выручка за последний год
                </div>
                <Range
                    withoutBorder
                    range={REVENUE_AMOUNT_RANGE_CONFIG}
                    value={state.revenueAmount}
                    onChange={value => didUpdatedState('revenueAmount', value)}
                    editable
                    editorConfig={FieldAmountValueConverter}
                    onEditComplete={revenueAmountRangeEditorHandler}
                    labelRender={() => <div className={styles['range-label']}>{formatAmountShortText(state.revenueAmount)}</div>}
                    lineClassName={styles['range-line']}
                />
            </div>
            <div className={styles['selectors']}>
                {SWITCHERS_CONFIG.map(item => (
                    <div key={item.label} className={styles['switcher-container']}>
                        <div className={styles['switcher-label']}>{item.label}</div>
                        <ToggleButton
                            value={state[item.id]}
                            onChange={value => didUpdatedState(item.id, value)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default connectFunctionalComponent(BorrowersCalculatorForm, {
    selectors: {
        state: calculatorHandler.selector,
        statuses: accountStatusesHandler.selector,
    },
    dispatch: {
        sendEvent,
        updateState: calculatorHandler.update,
    },
    fetch: {
        accountStatusesHandler,
    },

}) as React.FC<IProps>;