'use client';

import { formatPercentExt } from '@frontend/jetlend-core/src/formatters/formatUtils';
import React, {
    useCallback,
    useState,
} from 'react';
import { useWindowSize } from '@frontend/jetlend-web-ui/src/utils/responsive';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './PieChartBlock.module.scss';
import PieChart, {
    CHART_COLORS,
    IProps,
} from './PieChart/PieChart';

/**
 * Круговой график с легендой
 */
export default function PieChartBlock({
    section,
    chartData,
}: IProps) {
    const { width } = useWindowSize();

    const [isShowMore, setIsShowMore] = useState(false);
    const isMobile = width < 1366;
    const isHideData = isMobile && chartData?.values?.length > 6;

    const displayedData = (isHideData && !isShowMore)
        ? (Array.isArray(chartData?.values) && chartData.values.slice(0, 5))
        : chartData?.values;

    const didShowMode = useCallback(() => {
        setIsShowMore(!isShowMore);
    }, [isShowMore]);

    const titleMobileClassNames = buildClassNames(styles, [
        'feature--title',
        'feature--title-mobile',
    ]);

    return (
        <>
            <h2 className={titleMobileClassNames}>
                {section.title}
            </h2>
            <div className={styles['feature']}>
                <div className={styles['feature--content']}>
                    <h2 className={styles['feature--title']}>{section.title}</h2>
                    <div className={styles['chart-items']}>
                        {displayedData?.map(({
                            title,
                            part,
                            bold,
                        }, index) => (
                            <div
                                key={title}
                                className={styles['chart-item']}
                            >
                                <div className={styles['chart-item__title']}>
                                    <div
                                        className={styles['chart-item__color']}
                                        style={{ backgroundColor: CHART_COLORS[displayedData.length - 1 === index && (!isHideData || isShowMore) ? CHART_COLORS.length - 1 : index] }}
                                    />
                                    <p
                                        className={buildClassNames(styles, [
                                            'chart-item__label',
                                            bold && 'chart-item__bold',
                                        ])}
                                    >
                                        {title}
                                    </p>
                                </div>
                                <p
                                    className={buildClassNames(styles, [
                                        'chart-item__value',
                                        bold && 'chart-item__bold',
                                    ])}
                                >
                                    {formatPercentExt(part, { fractionDigits: 2 })}
                                </p>
                            </div>
                        ))}
                        {isHideData && (
                            <Button
                                outline
                                containerClassName="h-width--full h-mt-20"
                                onClick={didShowMode}
                            >
                                {!isShowMore ? 'Смотреть все' : 'Скрыть'}
                            </Button>
                        )}
                    </div>
                </div>
                <div className={styles['feature--chart']}>
                    <PieChart
                        section={section}
                        chartData={chartData}
                    />
                </div>
            </div>
        </>
    );
}