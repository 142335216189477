import React from 'react';
import styles from './InvestmentsCreditPortfolioChartAxisTick.module.scss';
import {
    EXTRA_SMALL_BREAKPOINT,
    getLabelDXValue,
} from '@app/components/client/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChart/InvestmentsCreditPortfolioChart.logic';

interface IProps {
    /**
     * Координата X
     */
    x: number;

    /**
     * Координата Y
     */
    y: number;

    /**
     * Значение в точке
     */
    datum: number;

    /**
     * Если true — ось Y, если false — ось X
     */
    dependentAxis?: boolean;

    /**
     * Список годов, для отображения на мобилке
     */
    years?: string[];

    /**
     * Ширина экрана
     */
    width?: number;

    /**
     * Количество кварталов в последнем году
     */
    quartersCount?: number;
}

export default function InvestmentsCreditPortfolioChartAxisTick({
    x,
    y,
    datum,
    years,
    dependentAxis,
    width,
    quartersCount,
}: Partial<IProps>) {
    // X Axis
    if (!dependentAxis) {
        if (Number.isInteger(datum)) {
            if (width > EXTRA_SMALL_BREAKPOINT) {
                return;
            }

            const dx = datum === 4 ? getLabelDXValue(quartersCount, width) : 0;

            return (
                <text
                    dx={dx}
                    dy="5"
                    x={x}
                    y={y}
                    textAnchor="middle"
                    className={styles['year']}
                >
                    {years?.[datum-1]} г
                </text>
            );
        }

        return (
            <rect
                x={x}
                y={y}
                dominantBaseline="start"
                className={styles['xAxis']}
            />
        );
    }

    // Y Axis
    return (
        <>
            {width > EXTRA_SMALL_BREAKPOINT && (
                <rect
                    x={x}
                    y={y}
                    className={styles['rect']}
                    rx={8}
                />
            )}
            <text
                dx="0"
                x={x}
                y={y}
                className={styles['value']}
                textAnchor="middle"
            >
                {datum}
            </text>
            <text
                dx="0"
                x={x}
                y={y}
                className={styles['text']}
            >
                млрд
            </text>
        </>
    );
};
