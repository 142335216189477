import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import Image from '@app/components/server/Image/Image';
import styles from './DocumentsNotFound.module.scss';
import NotFound from  '@app/icons/documents-not-found.svg';

interface IProps {
    className?: string;
}

const DocumentsNotFound : React.FC<IProps> = props => {
    const { className } = props;
    return (
        <div className={mergeClassNames([styles['not-found'], className])}>
            <div className={styles['image-wrapper']}>
                <Image src={NotFound} alt="" fill />
            </div>
            <h6 className={styles['title']}>Ничего не было найдено</h6>
            <p className={styles['description']}>Мы не смогли найти, что соответствовало бы вашему запросу. Попробуйте изменить ключевое слово для получения лучшего результата.</p>
        </div>
    );
};

export default DocumentsNotFound;

