export type MacroRatingGroup = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2';
export const ALL_MACRO_RATINGS: MacroRatingGroup[] = [ 'A1', 'A2', 'B1', 'B2', 'C1', 'C2' ];

export type RatingGroup = 'A' | 'B' | 'C';
export type RatingType =
    'A'|'AA'|'AAA'|'A+'|'AA+'|'AAA+'|
    'B'|'BB'|'BBB'|'B+'|'BB+'|'BBB+'|
    'C'|'CC'|'CCC'|'C+'|'CC+'|'CCC+'|
    '-';

export const RATING_LOST = '-';

export const RATINGS_MAP: Record<RatingType, string> = {
    'AAA+': '1',
    'AAA': '2',
    'AA+': '3',
    'AA': '4',
    'A+': '5',
    'A': '6',
    'BBB+': '7',
    'BBB': '8',
    'BB+': '9',
    'BB': '10',
    'B+': '11',
    'B': '12',
    'CCC+': '13',
    'CCC': '14',
    'CC+': '15',
    'CC': '16',
    'C+': '17',
    'C': '18',
    '-': '-',
};

export const RATINGS_GROUPS_MAP: Record<RatingGroup | MacroRatingGroup, string> = {
    'A': '1-6',
    'A1': '1-3',
    'A2': '4-6',
    'B': '7-12',
    'B1': '7-9',
    'B2': '10-12',
    'C': '13-18',
    'C1': '13-15',
    'C2': '16-18',
};

export const ALL_AVAILABLE_RATINGS: RatingType[] = Object.keys(RATINGS_MAP).filter(r => r !== RATING_LOST) as RatingType[];
export const ALL_AVAILABLE_RATINGS_WITH_EMPTY: RatingType[] = Object.keys(RATINGS_MAP).map(item => item === '-' ? 'empty' : item) as RatingType[];

export const FIRST_RATINGS_GROUP: RatingType[] = [
    'AAA+', 'AAA', 'AA+', 'AA', 'A+', 'A',
];

export const SECOND_RATINGS_GROUP: RatingType[] = [
    'BBB+', 'BBB', 'BB+', 'BB', 'B+', 'B',
];

export const THIRD_RATINGS_GROUP: RatingType[] = [
    'CCC+', 'CCC', 'CC+', 'CC', 'C+', 'C',
];

export const A1_RATINGS_GROUP: RatingType[] = [
    'AAA+', 'AAA', 'AA+',
];

export const A2_RATINGS_GROUP: RatingType[] = [
    'AA', 'A+', 'A',
];

export const B1_RATINGS_GROUP: RatingType[] = [
    'BBB+', 'BBB', 'BB+',
];

export const B2_RATINGS_GROUP: RatingType[] = [
    'BB', 'B+', 'B',
];

export const C1_RATINGS_GROUP: RatingType[] = [
    'CCC+', 'CCC', 'CC+',
];

export const C2_RATINGS_GROUP: RatingType[] = [
    'CC', 'C+', 'C',
];

export const MIN_RATING = 'AAA+';
export const MAX_RATING = 'C';

/**
 * Функция, определяющая к какому макро сегменту относится переданный рейтинг.
 *
 * @param rating Рейтинг, от "ААА+" до "C".
 */

export function getRatingGroup(rating: RatingType): MacroRatingGroup|undefined {
    if (A1_RATINGS_GROUP.includes(rating)) {
        return 'A1';
    }

    if (A2_RATINGS_GROUP.includes(rating)) {
        return 'A2';
    }

    if (B1_RATINGS_GROUP.includes(rating)) {
        return 'B1';
    }

    if (B2_RATINGS_GROUP.includes(rating)) {
        return 'B2';
    }

    if (C1_RATINGS_GROUP.includes(rating)) {
        return 'C1';
    }

    if (C2_RATINGS_GROUP.includes(rating)) {
        return 'C2';
    }

    return undefined;
}

export function ratingInGroup(rangeValues: RatingType[], ratingGroup: MacroRatingGroup) {
    const startGroup = getRatingGroup(rangeValues[0]) || 'A1';
    const endGroup = getRatingGroup(rangeValues[1]) || 'C2';

    return ratingGroup >= startGroup && ratingGroup <= endGroup;
}

/**
 * Функция, определяющая относится ли рейтинг к макро сегменту C2.
 *
 * @param rating Рейтинг, от "ААА+" до "C".
 */
export function isRatingInC2Group(rating: RatingType): boolean {
    return C2_RATINGS_GROUP.includes(rating);
}
