import {
    Children,
    Fragment,
    ReactNode,
    useMemo,
    useRef,
} from 'react';
import { SlideProps } from 'react-slideshow-image';
import dynamic from 'next/dynamic';
import styles from './AdvantagesSlider.module.scss';
import OffscreenLoader from '@app/components/server/ui/OffscreenLoader/OffscreenLoader';
import './slider.scss';

const LazySlide = dynamic(() => import('react-slideshow-image').then(module => module.Slide), { ssr: false });

export interface IProps {
    children: ReactNode;
    noBorder?: boolean;
    showNextSlide?: boolean;
}

const RESPONSIVE_SETTINGS = [
    {
        breakpoint: 1600,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 769,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 569,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 0,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
];

const AdvantagesSlider : React.FC<IProps> = props => {
    const {
        showNextSlide,
        children,
    } = props;

    const sliderRef = useRef<SlideProps | null>(null);

    const SLIDER_SETTINGS: SlideProps = useMemo(() => ({
        children: [],
        arrows: false,
        indicators: false,
        autoplay: false,
        infinite: true,
        cssClass: styles['slider'],
        duration: 1200,
        transitionDuration: 400,
        canSwipe: true,
        ref: sliderRef,
        responsive: RESPONSIVE_SETTINGS,
        slidesToScroll: 0,
    }), []);

    return (
        <OffscreenLoader>
            {({
                ref,
                inView,
            }) => (
                <div ref={ref} className={styles['container']}>
                    <div className={showNextSlide && styles['slim']}>
                        {inView && (
                            <LazySlide {...SLIDER_SETTINGS}>
                                {Children.map(children, (child, i) => (
                                    <Fragment key={i}>
                                        <div className={styles['item-wrapper']}>{child}</div>
                                    </Fragment>
                                ))}
                            </LazySlide>
                        )}
                    </div>
                </div>
            )}
        </OffscreenLoader>
    );
};

export default AdvantagesSlider;

