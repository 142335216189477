'use client';

import React, { useRef } from 'react';
import dynamic from 'next/dynamic';
import { SlideProps } from 'react-slideshow-image';
import {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import OffscreenLoader from '@app/components/server/ui/OffscreenLoader/OffscreenLoader';

import './slider.scss';

const LazySlide = dynamic(() => import('react-slick').then(module => module), { ssr: false });

const RESPONSIVE_SETTINGS = [
    {
        breakpoint: 1500,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 1120,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 568,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
];

const NextArrow = (
    <svg viewBox="0 0 100 100">
        <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#fff" transform="translate(100, 100) rotate(180) " />
    </svg>
);

const PrevArrow = (
    <svg viewBox="0 0 100 100">
        <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" fill="#fff" />
    </svg>
);

/**
 * Компонент для отрисовки слайдера статей по академии
 */
const AcademyNewsSlider = props => {
    const { children } = props;

    const sliderRef = useRef<SlideProps | null>(null);
    const { width: screenWidth } = useWindowSize();
    const isMobileScreen = isMobile(screenWidth);

    const SLIDER_SETTINGS = {
        children: [],
        arrows: isMobileScreen,
        indicators: false,
        autoplay: false,
        infinite: false,
        duration: 1200,
        transitionDuration: 400,
        className: 'academy-news-slider',
        canSwipe: true,
        ref: sliderRef,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: RESPONSIVE_SETTINGS,
        nextArrow: NextArrow,
        prevArrow: PrevArrow,
    };

    return (
        <OffscreenLoader>
            {({
                ref,
                inView,
            }) => (
                <div ref={ref}>
                    {inView && (
                        <LazySlide {...SLIDER_SETTINGS}>
                            {children?.map((child, i) => (
                                <React.Fragment key={i}>
                                    <div>{child}</div>
                                </React.Fragment>
                            ))}
                        </LazySlide>
                    )}
                </div>
            )}
        </OffscreenLoader>
    );
};

export default AcademyNewsSlider;