'use client';

import React from 'react';
import { buildRange } from '@frontend/jetlend-core/src/utils/arrayUtils';
import { buildClassNames } from '../../utils/classNameUtils';
import {
    isMobile,
    useWindowSize,
} from '../../utils/responsive';
import MarkItem from './MarkItem/MarkItem';

import styles from './markInput.module.scss';

export type MarkInputSize = 'small'|'normal'|'large';
export type MarkInputType = 'success'|'warning'|'error';

type Props = {
    size?: MarkInputSize;
    mark?: number;
    readonly?: boolean;
    type?: MarkInputType;

    lowValueHint?: string;
    highValueHint?: string;

    onChange?: (value: number) => void;
}

const MarkInput: React.FC<Props> = props => {
    const type = props.type ?? 'success';

    function renderItem(value) {
        return (
            <MarkItem
                key={value}
                value={value}
                readonly={props.readonly}
                selected={value <= props.mark}
                onClick={props.onChange}
                type={props.type}
            />
        );
    }

    const containerClassName = buildClassNames(styles, [
        props.readonly ? `container--readonly--${type}` : `container--${type}`,
        `container--size-${props.size || 'normal'}`,
    ]);

    const { width: windowWidth } = useWindowSize();
    const isMobileView = isMobile(windowWidth);

    return (
        <div className={styles['wrapper']}>
            {props.lowValueHint && !isMobileView &&
                <div className={styles['label']}>
                    {props.lowValueHint}
                </div>
            }
            <div className={containerClassName}>
                {buildRange(1, 5).map(v => renderItem(v))}
            </div>
            {props.highValueHint && !isMobileView &&
                <div className={styles['label']}>
                    {props.highValueHint}
                </div>
            }
            {(props.lowValueHint || props.highValueHint) && isMobileView &&
                <div className={styles['labels']}>
                    <div className={styles['label']}>
                        {props.lowValueHint}
                    </div>
                    <div className={styles['label']}>
                        {props.highValueHint}
                    </div>
                </div>
            }
        </div>
    );
};

export default MarkInput;
