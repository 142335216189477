import {
    BaseSectionWithBlocksAndDescription,
    HistoryFeatureType,
    IFeatureBlockApiModel,
} from '../common/common';

export enum MainPageSectionType {
    FEATURES = 'features',
    HOW_IT_WORKS = 'how_it_works',
    STATISTICS = 'statistics',
}

export interface IStatisticsBlockApiModel {
    title: string;
    value: string;
    description: string;
}

export enum IMainPageFeatureType {
    PROFITABILITY = 'profitability',
    SCORING_TIME = 'scoring-time',
    LIMIT = 'limit',
    INTEREST_RATE = 'interest_rate',
    REVOLTING_LINE = 'revolting-line',
    CONVENIENT_PRODUCT = 'convenient_product',
    TRUSTED_PRODUCT = 'trusted_product',
    TRUSTED_PRODUCT_2 = 'trusted_product_2',
    PRODUCT_LINKS = 'product_links',
}

export type MainPageFeaturesSectionApiModel = BaseSectionWithBlocksAndDescription<IFeatureBlockApiModel<IMainPageFeatureType | HistoryFeatureType>>
