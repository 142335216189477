'use client';

import styles from './PartnerCalculatorSection.module.scss';
import { sendEvent } from '@app/ducks/common/analytics';
import { startRegistration } from '@app/ducks/common/registration';
import {
    IBaseSectionApiModel,
    ISectionBasedProps,
} from '@app/models/common/common';
import PartnerCalculatorTitleCard from './PartnerCalculatorTitleCard/PartnerCalculatorTitleCard';
import PartnerCalculatorStepsCard from './PartnerCalculatorStepsCard/PartnerCalculatorStepsCard';
import PartnerCalculatorApplyCard from './PartnerCalculatorApplyCard/PartnerCalculatorApplyCard';
import PartnerCalculatorFormCard from './PartnerCalculatorFormCard/PartnerCalculatorFormCard';

type Props = ISectionBasedProps<IBaseSectionApiModel> & {
    startRegistration: typeof startRegistration;
    sendEvent: (eventName: string) => void;
};

const DEFAULT_TITLE = 'Начните зарабатывать прямо сейчас с JetLend';

const PartnerCalculatorSection: React.FC<Props> = props => {
    const {
        startRegistration,
        section,
    } = props;
    const { title = DEFAULT_TITLE } = section;

    return (
        <div className={styles['container']}>
            <PartnerCalculatorTitleCard className={styles['title-card']} titleText={title} />
            <PartnerCalculatorFormCard className={styles['calculator-card']} sendEvent={sendEvent} />
            <PartnerCalculatorStepsCard className={styles['steps-card']} startRegistration={startRegistration} />
            <PartnerCalculatorApplyCard className={styles['button-card']} />
        </div>
    );
};

export default PartnerCalculatorSection;
