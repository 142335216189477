import Image from 'next/image';
import { ReactNode } from 'react';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import Expand from 'react-expand-animated';
import styles from './Dropdown.module.scss';
import arrowIcon from '@app/icons/arrow-green-bottom.svg';

interface IProps {
    children: ReactNode;
    className: string;
    title: string;
    isOpen?: boolean;
    didDropDownClick?: () => void;
}

const Dropdown: React.FC<IProps> = props => {
    const {
        children,
        className,
        title,
        isOpen,
        didDropDownClick,
    } = props;

    return (
        <div
            className={mergeClassNames([
                className,
                styles['dropdown'],
            ])}
        >
            <div className={styles['dropdown-content-wrapper']}>
                <div className={styles['dropdown__button']} onClick={didDropDownClick}>
                    <span className={styles['dropdown__title']}>{title}</span>
                    <Image
                        className={buildClassNames(styles, ['dropdown__arrow', isOpen && 'dropdown__arrow_active'])}
                        src={arrowIcon}
                        alt=""
                        width={50}
                        height={50}
                    />
                </div>
                <Expand open={isOpen}>
                    <div className={styles['dropdown__content']}>{children}</div>
                </Expand>
            </div>
        </div>
    );
};

export default Dropdown;
