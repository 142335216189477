import React from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import PieChartBlock from '@app/components/client/common/PieChartBlock/PieChartBlock';
import { ISectionBasedProps } from '@app/models/common/common';
import { marketShareChartHandler } from '@app/ducks/investments';
import { IChartApiModel } from '@app/models/investments/investments';

/**
 * Круговой график "Доля на рынке"
 */
export default function InvestmentsMarketShareChart({ section }: ISectionBasedProps<IChartApiModel>) {
    const [chartData] = useSimpleApiHandler(marketShareChartHandler);

    return <PieChartBlock section={section} chartData={chartData} />;
}