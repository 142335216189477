import React, { useCallback } from 'react';
import { buildClassNames } from '../../../utils/classNameUtils';
import { MarkInputType } from '../MarkInput';

import styles from '../markInput.module.scss';

type Props = {
    value: number;
    selected?: boolean;
    readonly?: boolean;
    type?: MarkInputType;

    onClick?: (value: number) => void;
    onHover?: (value: number) => void;
}

const StarSolid = (color: string) => <svg className={buildClassNames(styles, ['button__icon', 'button__icon--solid'])} width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.4678 33.9402L22.0011 33.6939L21.5343 33.9403L9.73366 40.1686L11.9885 26.9733L12.0768 26.4566L11.702 26.09L2.14351 16.7397L15.3447 14.8142L15.8654 14.7382L16.0977 14.266L22.001 2.26576L27.9023 14.2659L28.1345 14.7382L28.6553 14.8142L41.8567 16.7397L32.3001 26.0901L31.9254 26.4567L32.0138 26.9734L34.2705 40.1686L22.4678 33.9402Z" fill={color} stroke={color} strokeWidth="2" /></svg>;
const StarRegular = (color: string) => <svg className={buildClassNames(styles, ['button__icon', 'button__icon--regular'])} width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.4678 33.9402L22.0011 33.6939L21.5343 33.9403L9.73366 40.1686L11.9885 26.9733L12.0768 26.4566L11.702 26.09L2.14351 16.7397L15.3447 14.8142L15.8654 14.7382L16.0977 14.266L22.001 2.26576L27.9023 14.2659L28.1345 14.7382L28.6553 14.8142L41.8567 16.7397L32.3001 26.0901L31.9254 26.4567L32.0138 26.9734L34.2705 40.1686L22.4678 33.9402Z" stroke={color} strokeWidth="2" /></svg>;


const MarkItem: React.FC<Props> = props => {
    const {
        value,
        onClick,
    } = props;
    const type = props.type ?? 'success';

    function getMarkColor(type: MarkInputType): string {
        switch (type) {
        case 'warning':
            return '#F2C94C';
        case 'error':
            return '#EB0038';
        default:
            return '#20836D';
        }
    }

    const didClicked = useCallback(() => onClick && onClick(value), [ onClick, value ]);

    const buttonClassName = buildClassNames(styles, [
        'button',
        props.selected && `button--selected--${type}`,
        props.readonly && 'button--readonly',
    ]);

    const icon = props.selected ? StarSolid(getMarkColor(type)) : StarRegular(getMarkColor(type));

    if (props.readonly) {
        return (
            <div className={buttonClassName}>
                {icon}
            </div>
        );
    }

    return (
        <button type="button" className={buttonClassName} onClick={didClicked}>
            {icon}
        </button>
    );
};

export default MarkItem;