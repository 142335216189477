'use client';

import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { useMemo } from 'react';
import styles from './InvestorsLiquidityDomTable.module.scss';
import InvestorsLiquidityDomTableRow from './InvestorsLiquidityDomTableRow';
import { ILiquidityDomRecord } from '@app/models/investors/investors';
import { investorsJetLendStatisticsHandler } from '@app/ducks/investors/investors';

const getMaxAbsoluteCount = (records: ILiquidityDomRecord[]) => {
    const maxPositiveCount = records.reduce((acc, item) => item.count > 0 ? Math.max(acc, item.count) : acc, 0.0);

    const maxNegativeCount = records.reduce((acc, item) => item.count < 0 ? Math.max(acc, Math.abs(item.count)) : acc, 0.0);

    return Math.max(Math.abs(maxPositiveCount), Math.abs(maxNegativeCount));
};

/**
 * Отображение примерного стакана вторичного рынка.
 */
export default function InvestorsLiquidityDomTable() {
    const [ publicStats ] = useSimpleApiHandler(investorsJetLendStatisticsHandler);

    const records = publicStats?.order_book;
    const maxCount = useMemo(() => getMaxAbsoluteCount(records ?? []), [ records ]);

    return (
        <table className={styles['table']}>
            <tbody>
                {records?.map(item => (
                    <InvestorsLiquidityDomTableRow
                        key={item.price}
                        item={item}
                        maxCount={maxCount}
                    />
                ))}
            </tbody>
        </table>
    );
};