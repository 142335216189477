import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import { useMemo } from 'react';
import { documentsPageStateHandler } from '@app/ducks/documents/documents';
import DocumentCard from '../DocumentCard/DocumentCard';
import DocumentsNotFound from '../DocumentsNotFound/DocumentsNotFound';

import styles from './DocumentsList.module.scss';

const DocumentsList : React.FC = () => {
    const [ state ] = useObjectHandler(documentsPageStateHandler);
    const { filteredDocuments } = state;

    const wasDocumentsFound = useMemo(() => Boolean(filteredDocuments?.length), [filteredDocuments]);

    return (
        wasDocumentsFound
            ? (filteredDocuments?.map((document, index) => (
                <DocumentCard
                    className={styles['card']}
                    key={`${document?.file?.url ?? ''}-${index}`}
                    document={document}
                />
            )))
            : <DocumentsNotFound className={styles['not-found']} />
    );
};

export default DocumentsList;

