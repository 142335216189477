import useObjectHandler from '@frontend/jetlend-core/src/hooks/useObjectHandler';
import SearchInput from '@frontend/jetlend-web-ui/src/ui/inputs/SearchInput/SearchInput';
import { useDispatch } from 'react-redux';
import {
    ChangeEvent,
    useCallback,
    useState,
} from 'react';
import { withDebounce } from '@frontend/jetlend-core/src/async/debounce';
import {
    clearSearch,
    documentsPageStateHandler,
    searchDocuments,
} from '@app/ducks/documents/documents';

interface IProps {
    className?: string;
}

const DocumentsSearch : React.FC<IProps> = props => {
    const { className } = props;
    const dispatch = useDispatch();
    const [ state ] = useObjectHandler(documentsPageStateHandler);
    const { search } = state;

    const [searchValue, setSearchValue] = useState(search);

    const searchAndFilterDocuments = useCallback((text: string) => dispatch(searchDocuments(text)), [dispatch]);

    const didSearchClear = useCallback(() => {
        dispatch(clearSearch());
        setSearchValue('');
    }, [dispatch]);

    const didSearchChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const searchText = e.currentTarget.value;
        setSearchValue(searchText);

        withDebounce(searchAndFilterDocuments, { timeout: 100 })(searchText);
    }, [searchAndFilterDocuments]);


    return (
        <div className={className}>
            <SearchInput
                placeholder="Введите название документа..."
                value={searchValue}
                onClear={didSearchClear}
                onChange={didSearchChanged}
            />
        </div>
    );
};

export default DocumentsSearch;

