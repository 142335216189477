'use client';

import React, {
    useCallback,
    useRef,
    useMemo,
} from 'react';
import MarkInput from '@frontend/jetlend-web-ui/src/components/MarkInput/MarkInput';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { formatDate } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { useResizeObserver } from '@frontend/jetlend-web-ui/src/hooks/useResizeObserver';
import Image from '@app/components/server/Image/Image';
import styles from './FeedbackItem.module.scss';
import { SERVICE_ICONS_MAP } from '@app/models/common/common';

export type IFeedbackItem = {
    service: string;
    text: string;
    date: string;
    rating: string;
    username: string;
    showFull?: boolean;
    index?: number;
    onMoreClick?: (index: number) => void;
};

const FeedbackItem: React.FC<IFeedbackItem> = props => {
    const textRef = useRef<HTMLDivElement>();
    const [, height] = useResizeObserver(textRef);

    const scrollHeight = textRef.current && textRef.current.scrollHeight;

    const isOverflow = useMemo(() => {
        if (!textRef.current) {
            return;
        }

        return height - scrollHeight < 0;
    }, [height, scrollHeight]);

    const didMoreClicked = useCallback(() => {
        props.onMoreClick(props.index);
    }, [props.index, props.onMoreClick]);

    const rating = props.rating ? parseInt(props.rating) : 5;

    const itemClassNames = buildClassNames(styles, ['container', props.showFull && 'full-container']);
    const textClassNames = buildClassNames(styles, ['text', props.showFull && 'full']);

    return (
        <div className={itemClassNames}>
            <div className={styles['top-block']}>
                <div className={styles['first-block']}>
                    <div className={styles['image-block']}>
                        <div className={styles['image-circle']}>
                            <Image
                                src={SERVICE_ICONS_MAP[props.service]}
                                alt=""
                                width={44}
                                height={44}
                            />
                        </div>
                        <div className={styles['name-block']}>
                            <h3 className={styles['title']}>{props.service}</h3>
                            <div className={styles['name']}>{props.username}</div>
                        </div>
                    </div>
                    <div>
                        <MarkInput readonly size="small" mark={rating} type="warning" />
                    </div>
                </div>
                <p
                    className={textClassNames}
                    ref={textRef}
                >
                    {props.text}
                </p>
            </div>
            <div className={styles['bottom-block']}>
                {isOverflow && !props.showFull
                    ? (
                        <button type="button" className={mergeClassNames([styles['more'], 'link'])} onClick={didMoreClicked}>
                            Читать все
                        </button>
                    )
                    : <div />
                }
                {props.date && <div className={styles['date']}>{formatDate(props.date)}</div>}
            </div>
        </div>
    );
};

export default FeedbackItem;