'use client';

import {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Department,
    IBasePerson,
} from '@app/models/aboutPlatform/aboutPlatform';
import { filterPersonsByDepartment } from '@app/ducks/aboutPlatform/aboutPlatform';
import PersonPopper from '../PersonPopper/PersonPopper';
import PersonImage from '@app/components/server/common/PersonImage/PersonImage';
import styles from './EmployeeDepartmentBlock.module.scss';
import { DEPARTMENT_CONFIGURATION } from './departments';
import FilterButtonsBlock from './FilterButtonsBlock';
import Dropdown from '@app/components/server/common/Dropdown/Dropdown';

interface IProps {
    persons: IBasePerson[];
}

const EmployeeDepartmentBlock = (props: IProps) => {
    const { persons } = props;
    const employeesRef = useRef<HTMLDivElement>(null);
    const [department, setDepartment] = useState(Department.ALL);
    const [personsArray, setPersonsArray] = useState(persons);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const currentDepartment = DEPARTMENT_CONFIGURATION[department];

    const didDepartmentSelect = useCallback((department: Department) => () => {
        setIsDropdownOpen(false);
        setDepartment(department);
    }, [setDepartment]);

    const didDropDownClick = useCallback(() => {
        setIsDropdownOpen(prev => !prev);
    }, [setIsDropdownOpen]);


    useEffect(() => {
        setPersonsArray(filterPersonsByDepartment(department, persons));
    }, [department, persons]);

    return (
        <>
            <div className={styles['dropdown-wrapper']}>
                <Dropdown
                    className={styles['dropdown']}
                    title={currentDepartment.text}
                    isOpen={isDropdownOpen}
                    didDropDownClick={didDropDownClick}
                >
                    <FilterButtonsBlock
                        departments={DEPARTMENT_CONFIGURATION}
                        filter={department}
                        onDepartmentSelect={didDepartmentSelect}
                    />
                </Dropdown>
            </div>
            <div className={styles['filter']}>
                <FilterButtonsBlock
                    departments={DEPARTMENT_CONFIGURATION}
                    filter={department}
                    onDepartmentSelect={didDepartmentSelect}
                />
            </div>
            <div
                className={styles['employees-wrapper']}
            >
                <div
                    className={styles['employees']}
                    ref={employeesRef}
                >
                    {personsArray.map((person) => (
                        <PersonPopper
                            person={person}
                            departmentIcon={DEPARTMENT_CONFIGURATION[person.department]?.icon || ''}
                            key={person.email}
                        >
                            <PersonImage
                                className={styles['photo']}
                                src={person.photo}
                                alt={person.name}
                                width={116}
                                height={116}
                            />
                        </PersonPopper>
                    ))}
                </div>
            </div>
        </>
    );
};

export default EmployeeDepartmentBlock;
